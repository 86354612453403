import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Drawer,
  FormControlLabel,
  styled,
  Tooltip,
  InputBase,
  LinearProgress,
} from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import "./NewMultiSelectStyle.scss";
import { useDispatch } from "react-redux";
import { updateValueAction } from "../../../Store/Actions";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  CalendarToday,
  ExpandMore,
  Person2Outlined,
  Person3Outlined,
  PersonOutlined,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ListIcon from "@mui/icons-material/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NewMultiSelect = ({
  options,
  value,
  onChange,
  isLoading,
  dataTableRef,
  name,
  label,
  icon,
  placeHolder,
  optionLabel = "name",
  optionValue = "id",
  isSingle,
  ...props
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  let allOptions = props?.allOptions ?? options;

  const handelSelect = (e) => {
    if (isSingle) {
      handelSingleSelect(e);
    } else {
      handelMultiSelect(e);
    }
  };

  const handelSingleSelect = (e) => {
    if (value === e?.target?.name) {
      dispatch(updateValueAction(dataTableRef, name, null));
      return;
    }
    dispatch(updateValueAction(dataTableRef, name, e?.target?.name));
  };

  const handelMultiSelect = (e) => {
    if (!value) {
      dispatch(updateValueAction(dataTableRef, name, [e?.target?.name]));
      return;
    }
    if (!value?.includes(e?.target?.name)) {
      dispatch(
        updateValueAction(dataTableRef, name, [...value, e?.target?.name])
      );
      return;
    }
    dispatch(
      updateValueAction(
        dataTableRef,
        name,
        value?.filter((val) => val !== e?.target?.name)
      )
    );
  };

  const handelClear = (e) => {
    e.stopPropagation();
    dispatch(updateValueAction(dataTableRef, name, isSingle ? null : []));
  };

  const handelRemoveSelection = (e, item) => {
    e.stopPropagation();
    dispatch(
      updateValueAction(
        dataTableRef,
        name,
        value?.filter((val) => val !== item?.[optionValue])
      )
    );
  };

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#fff",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#099bf0",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&::before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#099bf0",
    },
  });

  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }

  const filteredOptions = (isLoading ? [] : options)?.filter((item) =>
    item?.[optionLabel].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedOptions = (isLoading ? [] : allOptions)?.filter((item) =>
    value?.includes(item?.[optionValue])
  );

  const selectedOptionsSliced = selectedOptions?.slice(0, 3);

  const renderIcon = () => {
    switch (icon) {
      case "people":
        return (
          <PeopleOutlineIcon
            fontSize="small"
            sx={{ color: open ? "#009EFB" : "#D6D3D3" }}
          />
        );
      case "person":
        return (
          <PersonOutlined
            fontSize="small"
            sx={{ color: open ? "#009EFB" : "#D6D3D3" }}
          />
        );
      case "list":
        return (
          <ListIcon
            fontSize="small"
            sx={{ color: open ? "#009EFB" : "#D6D3D3" }}
          />
        );
      case "calendar":
        return (
          <CalendarToday
            sx={{ color: open ? "#009EFB" : "#D6D3D3", fontSize: 15 }}
          />
        );
      case "building":
        return (
          <FontAwesomeIcon
            icon={faBuilding}
            color={open ? "#009EFB" : "#D6D3D3"}
          />
        );
      case "office":
        return (
          <FontAwesomeIcon
            icon={faMailBulk}
            color={open ? "#009EFB" : "#D6D3D3"}
          />
        );
      default:
        break;
    }
  };

  const handelToggel = () => {
    if (!isLoading) {
      setOpen((prev) => {
        if (prev) setSearchTerm("");
        return !prev;
      });
    }
  };

  return (
    <>
      <div className="new-multi-select-root-container">
        <label
          className={`new-multi-select-label-style ${
            open ? "font-weight-500" : "font-weight-400"
          }`}
        >
          {label}
        </label>
        <div className="select-header" onClick={handelToggel}>
          {renderIcon()}
          {selectedOptionsSliced?.length || (isSingle && value) ? (
            <div className="value-container">
              {isSingle ? (
                <div>
                  {
                    options.find((opt) => opt?.[optionValue] === value)?.[
                      optionLabel
                    ]
                  }
                </div>
              ) : (
                <>
                  {selectedOptionsSliced.map((item) => (
                    <div
                      className={`name-card ${open ? "name-card-active" : ""}`}
                      key={item?.[optionValue]}
                    >
                      <span className="name-card-text">
                        {item?.[optionLabel]}
                      </span>
                      <span
                        className="name-card-cancel"
                        onClick={(e) => handelRemoveSelection(e, item)}
                      >
                        <CloseIcon
                          sx={{
                            color: open ? "#009EFB" : "#898a8d",
                            fontSize: 13,
                          }}
                        />
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <div className="new-multi-select-placeholder-style">
              {placeHolder}
            </div>
          )}
          {value?.length > 3 ? (
            <div
              className={`remaining-container ${
                open ? "remaining-container-active" : ""
              }`}
            >{`+${value?.length - 3}`}</div>
          ) : null}

          {value?.length ? (
            <div onClick={handelClear} className="pointer">
              <CloseIcon
                sx={{ color: open ? "#009EFB" : "#898a8d", fontSize: 16 }}
              />
            </div>
          ) : null}
          {open ? (
            <div>
              <ExpandLessIcon sx={{ color: "#009EFB" }} />
            </div>
          ) : (
            <div>
              <ExpandMore sx={{ color: "#D6D3D3" }} />
            </div>
          )}
        </div>
        {isLoading ? (
          <LinearProgress
            sx={{
              backgroundColor: "#b0b2b66f",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#b0b2b6c6",
              },
              "& .MuiLinearProgress-bar1Buffer": {
                backgroundColor: "#b0b2b690",
              },
              height: 2,
            }}
          />
        ) : open ? null : (
          <div className="closed-border"></div>
        )}
        <Collapse in={open}>
          <div className="select-check-box-container">
            <div className="select-check-box-scroll-container">
              {open ? (
                <InputBase
                  placeholder={t("search") + "..."}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoFocus={true}
                  sx={{
                    marginTop: 1,
                    marginBottom: 2,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    width: "100%",
                    background: "#f1f3f4",
                    borderRadius: "5px",
                  }}
                />
              ) : null}
              {filteredOptions?.map((opt) => (
                <div>
                  <FormControlLabel
                    control={
                      <BpCheckbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#099bf0",
                          },
                        }}
                        onChange={handelSelect}
                        name={opt?.[optionValue]}
                        checked={value?.includes(opt?.[optionValue])}
                      />
                    }
                    label={opt?.[optionLabel]}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        fontOpticalSizing: "auto",
                        fontStyle: "normal",
                        fontWeight: 500,
                        color: "#545A62",
                        fontSize: 14,
                      },
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end other-text-styles">
              <span className="plus-jakarta-sans ">
                {`${value?.length} of ${options?.length}`}
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default NewMultiSelect;
