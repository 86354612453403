import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import moment from "moment";
import gql from "graphql-tag";

import {
  BSelect,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Loader from "../Loader";
import MainModal from "../MainModal";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

/**
 * Mutations & Queries
 */

const GET_SIGN_IN_ON_BEHALF_INFO = gql`
  query GetSignInInfo(
    $user_id: ID
    $date: String
    $work_timing_id: ID
    $applicable_type_config_id: ID
    $applicable_type_configuration: String
  ) {
    get_sign_in_info(
      date: $date
      user_id: $user_id
      work_timing_id: $work_timing_id
      applicable_type_configuration: $applicable_type_configuration
      applicable_type_config_id: $applicable_type_config_id
    ) {
      allow_home: from_home
      expected_sign_in_time
      allowed_locations {
        id
        name
      }
    }
  }
`;

const GET_SIGN_OUT_ON_BEHALF_INFO = gql`
  query GetSignOutInfo($sign_in_id: ID, $user_id: ID) {
    get_sign_out_info(sign_in_id: $sign_in_id, user_id: $user_id) {
      work_hours_exceeded
      expected_sign_out_time
      allowed_locations {
        id
        name
      }
    }
  }
`;

const SIGN_IN_ON_BEHALF_MUTATION = gql`
  mutation SignInOnBehalf($input: SignInOnBehalfInput) {
    sign_in_on_behalf(input: $input) {
      id
      date
    }
  }
`;

const SIGN_OUT_ON_BEHALF_MUTATION = gql`
  mutation SignOutOnBehalf($input: SignOutOnBehalfInput) {
    sign_out_on_behalf(input: $input) {
      id
      date
    }
  }
`;

/**
 * Helpers
 */

const serializeDataQueryArgs = ({ isIn, ...data }) => {
  if (isIn) {
    return {
      user_id: data?.id,
      work_timing_id: data?.work_timing_id,
      date: moment.utc(data?.selectedDay).format("YYYY-MM-DD"),
      applicable_type_config_id: data?.applicable_type_config_id,
      applicable_type_configuration: data?.applicable_type_configuration,
    };
  } else {
    return {
      user_id: data?.id,
      sign_in_id: data?.sign_in_id,
    };
  }
};

const serializeDataMutationArgs = ({ isIn, ...data }) => {
  const date = moment.utc(data?.selectedDay).format("YYYY-MM-DD");
  const signDateTime = moment(
    `${data?.date} ${data?.time.format("HH:mm:ss")}`
  ).format("YYYY-MM-DD HH:mm");

  if (isIn) {
    return {
      date,
      user_id: data?.id,
      sign_in_time: signDateTime,
      work_timing_id: data?.work_timing_id,
      location_id: data?.location?.id || null,
      exception_id: data?.exception_id || null,
      from_home: data?.allow_home ? +data?.from_home : 0,
      applicable_type_config_id: data?.applicable_type_config_id,
      applicable_type_configuration: data?.applicable_type_configuration,
    };
  } else {
    return {
      user_id: data?.id,
      sign_out_time: signDateTime,
      sign_in_id: data?.sign_in_id,
      location_id: data?.location?.id || null,
      skip_early_sign_out_validation: +data?.forceSignOut,
    };
  }
};

/**
 * Modal
 */

const SignInOutOnBehalfModal = ({ onClose, refetchList, ...props }) => {
  const { t } = useTranslation();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [forceSignOut, setForceSignOut] = React.useState(false);
  const [options, setOptions] = React.useState({ locations: [] });
  const [data, setData] = React.useState({
    ...props?.data,
    time: null,
    location: null,
    date: moment(props?.data?.selectedDate).format("YYYY-MM-DD"),
    ...(props?.data?.isIn && { from_home: 0 }),
  });

  // Server State
  const [signInOut, { loading }] = useCustomMutation(
    data?.isIn ? SIGN_IN_ON_BEHALF_MUTATION : SIGN_OUT_ON_BEHALF_MUTATION
  );
  const { loading: dataLoading } = useQuery(
    data?.isIn ? GET_SIGN_IN_ON_BEHALF_INFO : GET_SIGN_OUT_ON_BEHALF_INFO,
    {
      variables: serializeDataQueryArgs(data),
      onCompleted: (d = {}) => {
        const {
          allowed_locations = [],
          expected_sign_in_time,
          expected_sign_out_time,
          ...rest
        } = data?.isIn ? d?.get_sign_in_info || {} : d?.get_sign_out_info || {};

        setOptions((prev) => ({ ...prev, locations: allowed_locations || [] }));
        setData((prev) => ({
          ...prev,
          ...(expected_sign_in_time && {
            time: moment(expected_sign_in_time),
            date: moment(expected_sign_in_time).format("YYYY-MM-DD"),
          }),
          ...(expected_sign_out_time && {
            time: moment(expected_sign_out_time),
            date: moment(expected_sign_out_time).format("YYYY-MM-DD"),
          }),
          ...rest,
        }));
      },
    }
  );

  // Constants
  const dateOptions = [
    {
      label: moment(data?.selectedDate)
        .subtract(1, "day")
        .format("ddd DD/MM/YYYY"),
      value: moment(data?.selectedDate).subtract(1, "day").format("YYYY-MM-DD"),
    },
    {
      label: moment(data?.selectedDate).format("ddd DD/MM/YYYY"),
      value: moment(data?.selectedDate).format("YYYY-MM-DD"),
    },
    {
      label: moment(data?.selectedDate).add(1, "day").format("ddd DD/MM/YYYY"),
      value: moment(data?.selectedDate).add(1, "day").format("YYYY-MM-DD"),
    },
  ];

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleDateChange = (name, val) => {
    setData((prev) => ({ ...prev, [name]: val }));
    setErrMsg("");
    setForceSignOut(false);
  };

  const handleSelectChange = (val, { name: key }) => {
    setData((prev) => ({ ...prev, [key]: val }));
  };

  const handleCheckboxChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: !prev[e.target.name] }));
  };

  const handleCloseModal = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (!data?.time) return setErrMsg("Please select time");

    signInOut({
      refetchList,
      onCloseModal: handleCloseModal,
      variables: {
        input: serializeDataMutationArgs({ forceSignOut, ...data }),
      },
      onError: (err) => {
        setErrMsg(err?.[0]?.extensions?.reason ?? err.message);

        if (
          !err?.[0]?.extensions?.parameters?.workhoursExceed &&
          typeof err?.[0]?.extensions?.parameters?.workhoursExceed === "boolean"
        ) {
          setForceSignOut(true);
        }
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle={`${data?.name} - ${moment().format("ddd DD/MM/YYYY")}`}
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
      btnColor={!data?.isIn && errMsg ? "danger" : undefined}
      btnLabel={
        data?.isIn ? "sign in" : forceSignOut ? "force sign out" : "sign out"
      }
    >
      {dataLoading ? <Loader fixed /> : null}

      <div className="d-flex align-items-center gap-5 mb-3">
        <AccessTimeIcon color="primary" sx={{ fontSize: 16 }} />
        {data?.work_timing_name}
      </div>

      <RadioboxForm
        name="date"
        label={data?.isIn ? "Sign-In Time" : "Sign-out Time"}
        labelStyle="mb-2"
        value={data?.date}
        options={dateOptions}
        interceptChange={(_, val) => handleDateChange("date", val)}
        optionsContainerStyle="row"
        optionItemStyle="col-md-4"
        optionInputStyle=" "
      />
      <DateTimePickerForm
        timeOnly
        name="time"
        value={data?.time}
        labelStyle="mb-2"
        containerStyle="mb-2"
        onIntercept={(val) => handleDateChange("time", val)}
      />

      <BSelect
        name="location"
        label="location"
        value={data?.location}
        onChange={handleSelectChange}
        options={options?.locations}
      />

      {data?.isIn && data?.allow_home ? (
        <CheckboxBooleanForm
          name="from_home"
          containerStyle="mt-3 mb-1"
          checked={data?.from_home}
          options={["work remotely"]}
          onChange={handleCheckboxChange}
        />
      ) : null}

      {errMsg ? (
        <p role="alert" className="text-danger mb-0 mt-3">
          {errMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default SignInOutOnBehalfModal;
