import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  handleActionMutation,
  checkCompanyPrivileges,
  serializeUpsertWorkRemotely,
  serializeUpsertHolidayException,
  serializeUpsertDayOffException,
} from "../../Helpers/HelperFns";
import { DateTimePickerForm, RadioboxForm, BSelect } from "form-builder";
import {
  fetchEmployeeProfileSuccess,
  onFormResetAction,
  onInputResetAction,
  onInputResetWithValueAction,
  showSuccessToast,
} from "../../Store/Actions";
import {
  GET_WORKING_TIMMING_OPTIONS_QUERY,
  employeeProfileQuery,
  fetchAttendanceTypeModalOptions,
  getAttendanceProfileQuery,
} from "../../Graphql/query";
import { upsertAttendanceTypeMutation } from "../../Graphql/mutation";
import MainModal from "../MainModal";
import moment from "moment";
import { setAttTypeServerValidationAction } from "../../Store/Actions/shiftsActions/actions";
import WorkRemotely from "../SharedFormSections/WorkRemotely";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Constants from "../../Constants";
import Swal from "sweetalert2";
import {
  Requests,
  WorkPlaces,
  Compensation,
  WorkOnDaysOff,
} from "../SharedFormSections/DayOffException";
import Privilages from "../../Constants/Privilages";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import MultipleCheckIn from "../SharedFormSections/MultipleCheckIn";
import CheckInSettings from "../CheckInSettings";
import WorkOnHolidays from "../SharedFormSections/WorkOnHolidays";
import _ from "lodash";

//   form props
const formName = "employeeWorkScheduleForm";
const formNameValidation = "employeeWorkScheduleFormValidation";
const formServerValidation = "employeeWorkScheduleFormServerValidation";
const reducerName = "shifts";

const AttendanceTypeModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchEmployeeWorkSchedules,
  previousLeaveAndBreakProfileId,
}) => {
  const isCompanyHasShift = checkCompanyPrivileges({
    privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
  });

  const formNameValidationData = useSelector(
    (state) => state?.shifts?.[formNameValidation]
  );

  const startingFrom = useSelector(
    (state) => state?.[reducerName]?.[formName]?.start
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generalExceptionMsg, setGeneralExceptionMsg] = useState("");
  const { userId } = useParams();

  const [upsertAttendanceType, { loading: upsertAttTypeLoading }] = useMutation(
    upsertAttendanceTypeMutation
  );

  const [
    fetchScheduleOptions,
    { data: scheduleOptions, loading: scheduleOptionsLoading },
  ] = useLazyQuery(fetchAttendanceTypeModalOptions);

  const { t } = useTranslation();

  const hasActiveSubscription = useSelector(
    (state) => state?.auth?.userProfile?.company?.hasActiveSubscription
  );

  const [fetchEmployeeProfileFunc] = useLazyQuery(employeeProfileQuery, {
    variables: {
      id: userId,
      isExpired: !hasActiveSubscription,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      dispatch(fetchEmployeeProfileSuccess(data?.user));
    },
  });

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer: reducerName,
  };

  // Redux Hooks
  const employeeScheduleForm = useSelector(
    (state) => state?.[reducerName]?.[formName]
  );

  const prevTemployeeWorkScheduleForm = useSelector(
    (state) => state?.[reducerName]?.prevTemployeeWorkScheduleForm
  );

  const employeeScheduleFormValidation = useSelector(
    (state) => state?.[reducerName]?.[formNameValidation]
  );

  const {
    data: getAttendanceProfileOptions,
    loading: getAttendanceProfileLoading,
  } = useQuery(getAttendanceProfileQuery, {
    variables: {
      archive: moment(startingFrom, "YYYY/MM/DD").format("YYYY-MM-DD"),
    },
    skip:
      !Boolean(startingFrom) ||
      employeeScheduleForm?.attendanceType ===
        Constants.attendanceTypes.SHIFT_BASED,
  });

  const { data: workTimingsOptions, loading: workTimingsLoading } = useQuery(
    GET_WORKING_TIMMING_OPTIONS_QUERY,
    {
      variables: {
        work_timming_date: moment(startingFrom, "YYYY/MM/DD").format(
          "YYYY-MM-DD"
        ),
      },
      skip:
        !Boolean(startingFrom) ||
        employeeScheduleForm?.attendanceType !=
          Constants.attendanceTypes.SHIFT_BASED,
    }
  );

  const dispatch = useDispatch();

  // start of handle work time options consist of worktime menu data and old selected work time data
  const serilazedNormalWorkTimingArray = [
    workTimingsOptions?.work_timings_menu ?? [],
    employeeScheduleForm?.normalWorkTiming ?? [],
  ].flat();

  const serilazedHalfWorkTimingArray = [
    workTimingsOptions?.half_work_timings_menu ?? [],
    [
      employeeScheduleForm?.firstHalfWorkTiming ?? {},
      employeeScheduleForm?.secondHalfWorkTiming ?? {},
    ] ?? [],
  ].flat();

  const normalWorkTimeOptions = _.uniqBy(serilazedNormalWorkTimingArray, "id");
  const halfWorkTimeOptions = _.uniqBy(serilazedHalfWorkTimingArray, "id");
  // End of handle work time options consist of worktime menu data and old selected work time data

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
      dispatch(onFormResetAction(formServerValidation));
      setGeneralExceptionMsg("");
    };
  }, []);

  useEffect(() => {
    setGeneralExceptionMsg("");
    if (employeeScheduleForm?.attendanceType) {
      const isTypeShift =
        employeeScheduleForm?.attendanceType ===
        Constants.attendanceTypes.SHIFT_BASED;
      if (!employeeScheduleForm?.permissionLeavesBreakSettingId) {
        dispatch(
          onInputResetWithValueAction(
            formName,
            "permissionLeavesBreakSettingId",
            previousLeaveAndBreakProfileId
          )
        );
      }

      fetchScheduleOptions({
        variables: {
          isTypeShift,
        },
      });
    }
  }, [employeeScheduleForm?.attendanceType]);

  const isDirty = () => {
    if (
      employeeScheduleForm?.attendanceType === "office based" &&
      moment(employeeScheduleForm?.start).isBefore(moment())
    ) {
      let prev = `${prevTemployeeWorkScheduleForm?.start}-${prevTemployeeWorkScheduleForm?.attendanceProfileId}`;
      let current = `${moment(employeeScheduleForm?.start).format(
        "YYYY-MM-DD"
      )}-${employeeScheduleForm?.attendanceProfileId}`;

      return prev !== current;
    } else {
      return false;
    }
  };

  const handleSubmitScheduleConfig = async () => {
    setIsSubmitting(true);
    setGeneralExceptionMsg("");

    if (employeeScheduleFormValidation?.length === 0) {
      dispatch(setAttTypeServerValidationAction({}));
      let input = {
        id: employeeScheduleForm?.id,
        user_id: userId,
        start: moment(employeeScheduleForm?.start).format("YYYY-MM-DD"),
        type: employeeScheduleForm?.attendanceType,
        workRemotelySettings: serializeUpsertWorkRemotely(employeeScheduleForm),
        weekStartDay:
          employeeScheduleForm?.attendanceType ===
          Constants.attendanceTypes.SHIFT_BASED
            ? +employeeScheduleForm?.first_day_of_the_week
            : null,

        ...(employeeScheduleForm?.attendanceType ===
          Constants.attendanceTypes.OFFICE_BASED && {
          attendanceProfileId: employeeScheduleForm?.attendanceProfileId,
        }),

        ...(employeeScheduleForm?.attendanceType ===
          Constants.attendanceTypes.SHIFT_BASED && {
          permissionLeavesBreakSettingId:
            employeeScheduleForm?.permissionLeavesBreakSettingId,
          workGroupId: employeeScheduleForm?.workGroupId,
          workTeamId: employeeScheduleForm?.workTeamId,
          joiningFrom: moment(employeeScheduleForm?.start).format("YYYY-MM-DD"),
          allowMultipleCheckIns: employeeScheduleForm?.allowMultipleCheckIns
            ? true
            : false,
          checkInFormId: employeeScheduleForm?.check_in_form_id,
          checkOutFormId: employeeScheduleForm?.check_out_form_id,
          save_with: "default",

          // day off exception
          ...serializeUpsertDayOffException(employeeScheduleForm),

          check_in_distance_setting: !!employeeScheduleForm?.metres
            ? parseFloat(employeeScheduleForm?.metres)
            : 0,
          checkin_setting:
            employeeScheduleForm?.check_in_settings_as_work_timing_sign_in_settings ||
            !employeeScheduleForm?.allowMultipleCheckIns
              ? null
              : {
                  id: employeeScheduleForm?.check_in_setting_id,
                  face_recognition_check_in:
                    !!employeeScheduleForm?.reuiqre_facial_recognition,
                  face_recognition_check_out:
                    !!employeeScheduleForm?.reuiqre_facial_recognition,

                  workplace_setting:
                    employeeScheduleForm?.employee_can_check_in_from,

                  additional_work_places:
                    employeeScheduleForm?.employee_can_check_in_from ==
                    "DEFAULT"
                      ? employeeScheduleForm?.check_in_specific_work_laces
                      : [],

                  workplace_setting_identical:
                    employeeScheduleForm?.apply_same_setting_when_checking_out,

                  workplace_setting_signout:
                    employeeScheduleForm?.apply_same_setting_when_checking_out
                      ? employeeScheduleForm?.employee_can_check_in_from
                      : employeeScheduleForm?.employee_can_check_out_from,

                  additional_work_places_signout:
                    employeeScheduleForm?.apply_same_setting_when_checking_out
                      ? employeeScheduleForm?.employee_can_check_in_from ==
                        "DEFAULT"
                        ? employeeScheduleForm?.check_in_specific_work_laces
                        : []
                      : employeeScheduleForm?.employee_can_check_out_from ==
                        "DEFAULT"
                      ? employeeScheduleForm?.check_out_specific_work_laces
                      : [],
                },

          // holiday exception
          ...serializeUpsertHolidayException(employeeScheduleForm),
        }),
      };

      if (isDirty()) {
        const values = await Swal.fire({
          icon: "warning",
          text: t("recalc_swal"),
          input: "checkbox",
          inputPlaceholder: t("skip sign-in records"),
          inputValue: 1,
          returnInputValueOnDeny: true,
          showConfirmButton: true,
          confirmButtonText: t("Yes"),
          confirmButtonColor: "#7cd1f9",
          showDenyButton: true,
          denyButtonText: t("No"),
          showCancelButton: true,
          cancelButtonText: t("Cancel"),
          customClass: {
            popup: "swal-warning-style",
            icon: "swal-icon",
            cancelButton: "order-1",
            denyButton: "order-2",
            confirmButton: "order-3",
            actions: "confirm-modal-actions",
          },
        });

        let checkboxValue = values.value === 0 ? false : true;

        if (values.isDismissed) {
          return;
        }

        if (values) {
          input = {
            ...input,
            save_with: values.isConfirmed
              ? "recalculate"
              : "without_recalculate",
            skip_work_timing_changed: checkboxValue,
          };
        }
      }

      const submitFunc = async () => {
        const { response, error, serverError } = await handleActionMutation(
          upsertAttendanceType,
          {
            variables: {
              input,
            },
            isReturnUnion: true,
            disableFailedToast: true,
            disableSuccessToast: true,
            refetchQuery: fetchEmployeeWorkSchedules,
          }
        );

        if (
          response?.data?.updateOrCreateAttendanceTypeConfiguration
            ?.__typename === "GeneralException"
        ) {
          setGeneralExceptionMsg(
            response?.data?.updateOrCreateAttendanceTypeConfiguration
              ?.message ?? t("something went wrong")
          );
        }
        if (serverError) {
          return dispatch(setAttTypeServerValidationAction(serverError));
        }

        if (!error) {
          setIsModalOpen(false);
          fetchEmployeeProfileFunc();
        }
      };

      if (!employeeScheduleForm?.id) {
        Swal.fire({
          icon: "warning",
          title: t("are you sure"),
          text: t(
            "As a result of updating the attendance type configuration, all requests related to upcoming dates will be deleted as well as all ongoing leaves will end at the end of the previous configuration And any leaves accepted leaves past this date will need to be resubmitted"
          ),
          showConfirmButton: true,
          confirmButtonText: t("Yes"),
          confirmButtonColor: "#7cd1f9",
          showDenyButton: true,
          denyButtonText: t("No"),
          showCancelButton: false,
          customClass: {
            popup: "swal-warning-style",
            icon: "swal-icon",
            denyButton: "order-2",
            confirmButton: "order-3",
            htmlContainer: "attendance-type-alert-text",
          },
        }).then(async (values) => {
          if (values.isConfirmed) {
            submitFunc();
          }
        });

        return;
      }

      submitFunc();
    }
  };

  const selectedWorkGroupTeams = scheduleOptions?.work_groups_menu?.find(
    (item) => item?.id === employeeScheduleForm?.workGroupId
  )?.WorkTeams;

  const canEditConfiguration =
    employeeScheduleForm?.canEdit || !employeeScheduleForm?.id;

  const selectedAttProfile = scheduleOptions?.attendance_profiles?.data?.find(
    (attProfile) => attProfile?.id == employeeScheduleForm?.attendanceProfileId
  );

  const handleInterceptAttendanceProfileChange = () => {
    dispatch(onInputResetAction(formName, "home_days"));
  };

  const isShiftEnabled = isCompanyHasShift && canEditConfiguration;

  const handleWorkgropChange = (e) => {
    dispatch(onInputResetAction(formName, "workTeamId"));
  };

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    if (
      !employeeScheduleForm?.allow_work_on_day_off ||
      employeeScheduleForm?.attendanceType !==
        Constants.attendanceTypes.SHIFT_BASED
    ) {
      dispatch(onInputResetAction(formProps.formName, "allow_overtime"));
      dispatch(onInputResetAction(formProps.formName, "allow_permission"));
      dispatch(onInputResetAction(formProps.formName, "apply_compensation"));
      dispatch(onInputResetAction(formProps.formName, "allow_work_on_day_off"));
    }
  }, [
    employeeScheduleForm?.attendanceType,
    employeeScheduleForm?.allow_work_on_day_off,
  ]);

  return (
    <>
      <MainModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        modalTitle={t("new attendance type configuration")}
        className="work_schedule_modal"
        btnLabel={t("save")}
        btnOnClick={handleSubmitScheduleConfig}
        btnSubmitLoading={upsertAttTypeLoading}
      >
        <DateTimePickerForm
          {...formProps}
          datePickerContainer="w-100"
          label="Starting From"
          containerStyle="flex-column align-items-start"
          labelStyle="custom-label-style mb-2"
          name="start"
          validateBy="textRequired"
          validationName="input.start"
          disabled={!canEditConfiguration}
        />

        <h1 className="secondary_title my-3">{t("Schedule Type")}</h1>

        <RadioboxForm
          {...formProps}
          options={[
            {
              label: "Office Based Hours",
              value: "office based",
              optProps: { disabled: !canEditConfiguration },
            },
            {
              label: "shift based work schedule",
              value: "shifts",
              optProps: {
                disabled: !isShiftEnabled,
              },
            },
          ]}
          name="attendanceType"
          type="radio"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle row"
          optionItemStyle="col-6 px-0"
          optionInputStyle=" "
          optionLabelStyle="mb-0 mr-3"
          validateBy="textRequired"
          validationName="input.type"
        />

        {employeeScheduleForm?.attendanceType ===
        Constants.attendanceTypes.OFFICE_BASED ? (
          <div className="mt-3">
            <BSelect
              {...formProps}
              name="attendanceProfileId"
              label={t("attendance profile")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={getAttendanceProfileOptions?.attendance_profiles?.data}
              validateBy="textRequired"
              validationName="input.attendanceProfileId"
              skipLocalization
              dependOn="attendanceType"
              dependancyType="equal"
              dependancyValue={["office based"]}
              icon="person"
              isLoading={getAttendanceProfileLoading}
              isDisabled={!canEditConfiguration || getAttendanceProfileLoading}
              onInterceptInputOnChange={handleInterceptAttendanceProfileChange}
            />
          </div>
        ) : null}

        {employeeScheduleForm?.attendanceType ===
        Constants.attendanceTypes.SHIFT_BASED ? (
          <div className="row">
            <div className="col-lg-6">
              <BSelect
                {...formProps}
                name="permissionLeavesBreakSettingId"
                label={t("leave and break profile")}
                keepDefaultStyle
                placeholder={t("select option")}
                options={
                  scheduleOptions?.PermissionLeavesBreakSettingsForMenu?.data
                }
                validateBy="textRequired"
                validationName="input.permissionLeavesBreakSettingId"
                skipLocalization
                dependOn="attendanceType"
                dependancyType="equal"
                dependancyValue={["shifts"]}
                icon="person"
                isLoading={scheduleOptionsLoading}
                isDisabled={!canEditConfiguration}
              />
            </div>

            <div className="col-lg-6 pl-2">
              <BSelect
                {...formProps}
                name="workGroupId"
                label={t("work group")}
                keepDefaultStyle
                placeholder={t("select option")}
                options={scheduleOptions?.work_groups_menu}
                validationName="input.workGroupId"
                skipLocalization
                dependOn="attendanceType"
                dependancyType="equal"
                dependancyValue={["shifts"]}
                icon="calendar"
                isLoading={scheduleOptionsLoading}
                isClearable
                isDisabled={!canEditConfiguration}
                onInterceptInputOnChange={handleWorkgropChange}
              />
            </div>

            <div className="col-lg-6 mt-1">
              <BSelect
                {...formProps}
                name="workTeamId"
                label={t("work team")}
                keepDefaultStyle
                placeholder={t("select option")}
                options={selectedWorkGroupTeams}
                validationName="input.workTeamId"
                skipLocalization
                dependOn="attendanceType"
                dependancyType="equal"
                dependancyValue={["shifts"]}
                icon="people"
                isLoading={scheduleOptionsLoading}
                isClearable
                isDisabled={!canEditConfiguration}
              />
            </div>

            {/* <div className="col-lg-6 d-none">
              <DateTimePickerForm
                {...formProps}
                datePickerContainer="w-100"
                label="Join From"
                labelStyle="custom-label-style"
                name="joiningFrom"
                dependOn="workTeamId"
                dependancyType="greater"
                dependancyValue={[0]}
                validationName={`input.joiningFrom`}
                validateBy="textRequired"
                validateMessage={
                  formNameValidationData?.includes("joiningFrom")
                    ? t("joining date is required when a team is selected")
                    : null
                }
                disabled={!canEditConfiguration}
              />
            </div> */}

            <div className="col-12 mt-2">
              <BSelect
                {...formProps}
                label={t("first day of the week")}
                name="first_day_of_the_week"
                keepDefaultStyle
                placeholder={t("select option")}
                hideSelectedOptions
                optionLabel="label"
                optionValue="value"
                options={Constants.WeekDays}
                containerStyle="row justify-content-between align-items-center my-3"
                labelStyle="col-12 mb-2"
                inputContainerStyle="col-12"
                validationName="input.user_input.weekStartDay"
                validateBy={
                  employeeScheduleForm?.attendanceType ===
                  Constants.attendanceTypes.SHIFT_BASED
                    ? "textRequired"
                    : false
                }
                icon={<CalendarToday />}
              />
            </div>

            {/* Check-ins */}
            <div className="col-12 mt-2">
              <MultipleCheckIn
                isShifts
                formProps={formProps}
                name="allowMultipleCheckIns"
                allow_customize_check_ins={
                  employeeScheduleForm?.allow_customize_check_ins
                }
                customCheckFormIDs={{
                  in: employeeScheduleForm?.check_in_form_id,
                  out: employeeScheduleForm?.check_out_form_id,
                }}
              />
            </div>

            {employeeScheduleForm?.allowMultipleCheckIns ? (
              <div className="w-100 pr-3 pl-3">
                <CheckInSettings
                  reducer="shifts"
                  formProps={formProps}
                  workPlacesOptions={scheduleOptions?.locations_menu?.data}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        {/* Work Remotely Settings */}
        <strong className="d-block mt-4 mb-2 blue-color">
          {t("work remotely settings")}
        </strong>
        <WorkRemotely FormProps={formProps} />

        {employeeScheduleForm?.attendanceType ===
        Constants.attendanceTypes.SHIFT_BASED ? (
          <>
            {/* Work On Days Off */}
            <WorkOnDaysOff
              formProps={formProps}
              halfWorkTimingOptions={halfWorkTimeOptions}
              normalWorkTimingOptions={normalWorkTimeOptions}
            />

            <div className="ml-3">
              {employeeScheduleForm?.allow_work_on_day_off ? (
                <>
                  {/* Work Places */}
                  <WorkPlaces
                    formProps={formProps}
                    locationsOptions={scheduleOptions?.locations_menu?.data}
                  />

                  {!employeeScheduleForm?.treat_as_normal ? (
                    <>
                      {/* Compensation */}
                      <Compensation
                        formProps={formProps}
                        compensation_type={
                          employeeScheduleForm?.compensation_type
                        }
                        apply_compensation={
                          employeeScheduleForm?.apply_compensation
                        }
                      />

                      {/* Requests */}
                      <Requests formProps={formProps} />
                    </>
                  ) : null}
                </>
              ) : null}
            </div>

            <WorkOnHolidays
              halfWorkTimingOptions={halfWorkTimeOptions}
              normalWorkTimingOptions={normalWorkTimeOptions}
              formProps={formProps}
              locationsOptions={scheduleOptions?.locations_menu?.data}
              showWorkPlaceSection={true}
              serverValidationPrefix="input"
            />
          </>
        ) : null}

        {/* Validation */}
        <div className="invalid-container-style">
          <p className="mb-0 mt-4 validity-msg-style">{generalExceptionMsg}</p>
        </div>
      </MainModal>
    </>
  );
};

export default AttendanceTypeModal;
