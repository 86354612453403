import moment from "moment";
import Helper from "./index";

export const normalizefetchedLeavesBreaks = ({ data }) => {
  const normalizedData = data?.map((item) => {
    return {
      id: item?.id,
      name: item?.name,

      // annual leaves input
      ...normalizedFetchAnnualLeaveSettingInput(item?.annualLeaveSetting),

      probation_period_id: item?.probationPeriodConfiguration?.id,
      request_leaves: !item?.probationPeriodConfiguration?.request_leaves,
      settle_leaves_id: item?.settleLeavesConfigurations?.at(-1).id,
      remaining_balance_option_id:
        item?.settleLeavesConfigurations?.at(-1).remaining_balance_option_id,
      rollover_balance_automatic:
        item?.settleLeavesConfigurations?.at(-1).rollover_balance_automatic,
      allow_max_number_of_leaves_to_be_rolled:
        item?.settleLeavesConfigurations?.at(-1)
          .max_number_of_leaves_to_be_rolled !== null
          ? 1
          : 0,
      max_number_of_leaves_to_be_rolled:
        item?.settleLeavesConfigurations?.at(-1)
          .max_number_of_leaves_to_be_rolled,
      next_year: item?.settleLeavesConfigurations?.at(-1).next_year,
      payable_at:
        item?.settleLeavesConfigurations?.at(-1).payable_at < 10
          ? `0${item?.settleLeavesConfigurations?.at(-1).payable_at}`
          : item?.settleLeavesConfigurations?.at(-1).payable_at,
      rollover_expires_after: item?.settleLeavesConfigurations?.at(-1).rollover_expires_after,
      sick_leaves_id: item?.sickLeaveSetting?.id,
      allow_sick_leaves: item?.sickLeaveSetting?.allow_sick_leaves,
      allow_sick_leave_cutoff: item?.sickLeaveSetting?.allow_sick_leave_cutoff,
      sick_leave_cutoff: item?.sickLeaveSetting?.sick_leave_cutoff
        ? Helper.timeToHours(item?.sickLeaveSetting?.sick_leave_cutoff)
        : null,
      allowed_sick_leaves:
        item?.sickLeaveSetting?.allowed_sick_leaves?.toString(),
      include_weekends_and_holidays_in_sick:
        item?.sickLeaveSetting?.include_weekends_and_holidays_in_sick,
      unpaid_leaves_id: item?.unpaidLeaveSetting?.id,
      allow_unpaid_leaves: item?.unpaidLeaveSetting?.allow_unpaid_leaves,
      allow_unpaid_leave_cutoff:
        item?.unpaidLeaveSetting?.allow_unpaid_leave_cutoff,
      unpaid_leave_cutoff: item?.unpaidLeaveSetting?.unpaid_leave_cutoff
        ? Helper.timeToHours(item?.unpaidLeaveSetting?.unpaid_leave_cutoff)
        : null,
      include_weekends_and_holidays_in_unpaid:
        item?.unpaidLeaveSetting?.include_weekends_and_holidays_in_unpaid,
      permissions_id: item?.permissionSetting?.id,
      allow_permissions:
        Helper.timeToHours(item?.permissionSetting?.min_permission_unit) !== 0
          ? 1
          : 0,
      min_permission_unit: Helper.timeToHours(
        item?.permissionSetting?.min_permission_unit
      ),
      allow_permission_cutoff: item?.permissionSetting?.allow_permission_cutoff,
      permission_cutoff: item?.permissionSetting?.permission_cutoff
        ? Helper.timeToHours(item?.permissionSetting?.permission_cutoff)
        : null,
      max_units_per_day: item?.permissionSetting?.max_units_per_day,
      max_units_per_month: item?.permissionSetting?.max_units_per_month,
      breaks_id: item?.breakSetting?.id,
      allow_break: item?.breakSetting?.allow_break,
      number_of_allowed_break_sessions_per_day:
        item?.breakSetting?.number_of_allowed_break_sessions_per_day,
      maximum_break_session_duration: Math.ceil(
        Helper.timeToHours(item?.breakSetting?.maximum_break_session_duration) *
          60
      ),
      maximum_break_duration: Math.ceil(
        Helper.timeToHours(item?.breakSetting?.maximum_break_duration) * 60
      ),
      deduct_from_work_hours: item?.breakSetting?.deduct_from_work_hours,

      // request custom leaves input
      ...normalizeFetchCustomLeavesInput(item?.customLeaveSetting),

      // request ability input
      ...normalizeFetchRequestAbilityInput(item?.submitRequestAbility),
    };
  });

  return normalizedData;
};

export const normalizeSumbittedLeavesBreaks = (data) => {
  const submittedData = {
    id: data.id ? data.id : null,
    name: data.name,

    // annual leaves input
    annual_leave_setting: normalizedSubmitAnnualLeaveSettingInput(data),

    probation_period: {
      request_leaves: +!data.request_leaves,
    },
    settle_leaves: {
      remaining_balance_option_id: data.remaining_balance_option_id,
      ...(data.remaining_balance_option_id === "1"
        ? {
            rollover_balance_automatic: +data.rollover_balance_automatic,
            max_number_of_leaves_to_be_rolled:
              data.allow_max_number_of_leaves_to_be_rolled
                ? Number.parseFloat(data.max_number_of_leaves_to_be_rolled)
                : null,
            rollover_emergency_credit: data.rollover_emergency_credit ? 1 : 0,
          }
        : {
            rollover_balance_automatic: null,
            max_number_of_leaves_to_be_rolled: null,
            rollover_emergency_credit: null,
          }),
      next_year: data.next_year,
      payable_at:
        data.remaining_balance_option_id === "2" && !data.next_year
          ? "12"
          : data.remaining_balance_option_id === "2" && data.next_year
          ? data.payable_at
          : null,
      rollover_expires_after: data?.rollover_expires_after,
    },
    sick_leave_setting: {
      id: data.sick_leaves_id ? data.sick_leaves_id : null,
      sick_leaves: data.allow_sick_leaves,
      allowed_sick_leaves: data.allowed_sick_leaves
        ? data.allowed_sick_leaves?.toString()
        : null,
      include_weekends_and_holidays_in_sick:
        data.include_weekends_and_holidays_in_sick,
      allow_sick_leave_cutoff: data.allow_sick_leave_cutoff,
      sick_leave_cutoff: data.sick_leave_cutoff
        ? Helper.hoursToTime(data.sick_leave_cutoff)
        : null,
    },
    unpaid_leave_setting: {
      id: data.unpaid_leaves_id ? data.unpaid_leaves_id : null,
      unpaid_leaves: data.allow_unpaid_leaves,
      include_weekends_and_holidays_in_unpaid:
        data.include_weekends_and_holidays_in_unpaid,
      allow_unpaid_leave_cutoff: data.allow_unpaid_leave_cutoff,
      unpaid_leave_cutoff: data.unpaid_leave_cutoff
        ? Helper.hoursToTime(data.unpaid_leave_cutoff)
        : null,
    },
    permission_setting: {
      id: data.permissions_id ? data.permissions_id : null,
      allow_permissions: data.allow_permissions,
      min_permission_unit: Helper.hoursToTime(data.min_permission_unit),
      max_units_per_day: data.max_units_per_day,
      max_units_per_month: data.max_units_per_month,
      allow_permission_cutoff: data.allow_permission_cutoff,
      permission_cutoff: data.permission_cutoff
        ? Helper.hoursToTime(data.permission_cutoff)
        : null,
    },
    break_setting: {
      id: data.breaks_id ? data.breaks_id : null,
      allow_break: data.allow_break,
      number_of_allowed_break_sessions_per_day:
        data.number_of_allowed_break_sessions_per_day?.toString(),
      maximum_break_session_duration: Helper.hoursToTime(
        Number(data.maximum_break_session_duration) / 60
      ),
      maximum_break_duration: Helper.hoursToTime(
        Number(data.maximum_break_duration) / 60
      ),
      deduct_from_work_hours: data?.deduct_from_work_hours,
    },

    // custom leaves input
    customLeaveSetting: normalizeSubmitCustomLeavesInput(data),

    // request ability input
    submit_request_ability: normalizeSubmitRequestAbilityInput(data),
  };

  return submittedData;
};

/**
 * Start of AnnualLeaveSetting Input
 */

const normalizedSubmitAnnualLeaveSettingInput = (data) => {
  if (!data?.allow_annual_leaves) {
    return {
      id: data?.annual_leaves_id || null,
      applicable_type: data?.applicable_type,
      allow_annual_leaves: data?.allow_annual_leaves,
      allowed_annual_leaves: null,
      allowed_emergency_leaves: null,
    };
  } else {
    if (data?.applicable_type === "fixed") {
      switch (data?.leave_basis) {
        case "annual":
          return {
            id: data?.annual_leaves_id || null,
            applicable_type: data?.applicable_type,
            allow_annual_leaves: data?.allow_annual_leaves,
            allowed_annual_leaves: data.allowed_annual_leaves,
            annual_leave_cuttoff: Helper.hoursToTime(data.annual_leave_cuttoff),
            allowed_emergency_leaves:
              data?.allow_annual_leaves && data?.allow_emergency
                ? data.allowed_emergency_leaves
                : null,
            include_weekends_and_holidays_in_annual:
              data.include_weekends_and_holidays_in_annual,
            leave_basis: data.leave_basis,
            add_leaves_every: data?.add_leaves_every,
            ...(data?.add_leaves_every === "month" && {
              add_leaves_at: data?.add_leaves_at,
            }),
            deduct_leaves_against_unpaid_leaves:
              data?.deduct_leaves_against_unpaid_leaves,
            deduct_leaves_against_paid_leaves:
              data?.deduct_leaves_against_paid_leaves,
            deduct_leaves_against_absent_days:
              data?.deduct_leaves_against_absent_days,
            total_weighted_days: parseFloat(data.total_weighted_days),
          };

        case "accrual":
          return {
            id: data?.annual_leaves_id || null,
            applicable_type: data?.applicable_type,
            allow_annual_leaves: data?.allow_annual_leaves,
            allowed_annual_leaves: data.allowed_annual_leaves,
            annual_leave_cuttoff: Helper.hoursToTime(data.annual_leave_cuttoff),
            allowed_emergency_leaves:
              data?.allow_annual_leaves && data?.allow_emergency
                ? data.allowed_emergency_leaves
                : null,
            include_weekends_and_holidays_in_annual:
              data.include_weekends_and_holidays_in_annual,
            leave_basis: data.leave_basis,
            leave_basis_type: data?.leave_basis_type,
            add_leaves_at: data?.add_leaves_at,
            start_week_day_number:
              data?.add_leaves_at === "startOfWeek"
                ? +data?.start_week_day_number
                : null,
            deserved_against: data?.deserved_against,
            ...(data?.leave_basis_type === "workingHours" && {
              holiday_hours_count: parseFloat(data?.holiday_hours_count),
              day_off_hours_count: parseFloat(data?.day_off_hours_count),
              annual_leaves_deserved_against:
                data?.annual_leaves_deserved_against,
            }),
          };

        default:
          break;
      }
    }

    // if (data?.applicable_type === "flexible") {
    // }

    return {};
  }
};

const normalizedFetchAnnualLeaveSettingInput = (annualLeaveSetting = {}) => {
  return {
    annual_leaves_id: annualLeaveSetting?.id,

    allow_annual_leaves: annualLeaveSetting?.allow_annual_leaves,
    allowed_annual_leaves: annualLeaveSetting?.allowed_annual_leaves,

    allow_emergency:
      annualLeaveSetting?.allowed_emergency_leaves !== null ? 1 : 0,
    allowed_emergency_leaves: annualLeaveSetting?.allowed_emergency_leaves,

    leave_basis: annualLeaveSetting?.leave_basis,
    leave_basis_type: annualLeaveSetting?.leave_basis_type,

    add_leaves_at: annualLeaveSetting?.add_leaves_at,
    add_leaves_every: annualLeaveSetting?.add_leaves_every,

    applicable_type: annualLeaveSetting?.applicable_type,
    deserved_against: annualLeaveSetting?.deserved_against,

    total_weighted_days: annualLeaveSetting?.total_weighted_days,
    annual_leave_cuttoff: Helper.timeToHours(
      annualLeaveSetting?.annual_leave_cuttoff
    ),

    holiday_hours_count: annualLeaveSetting?.holiday_hours_count,
    day_off_hours_count: annualLeaveSetting?.day_off_hours_count,
    start_week_day_number: annualLeaveSetting?.start_week_day_number,
    include_weekends_and_holidays_in_annual:
      annualLeaveSetting?.include_weekends_and_holidays_in_annual,

    annual_leaves_deserved_against:
      annualLeaveSetting?.annual_leaves_deserved_against,
    deduct_leaves_against_unpaid_leaves:
      annualLeaveSetting?.deduct_leaves_against_unpaid_leaves,
    deduct_leaves_against_paid_leaves:
      annualLeaveSetting?.deduct_leaves_against_paid_leaves,
    deduct_leaves_against_absent_days:
      annualLeaveSetting?.deduct_leaves_against_absent_days,
  };
};

/**
 * End of AnnualLeaveSetting Input
 *
 * Start of Custom Leaves Input
 */

const normalizeFetchCustomLeavesInput = (custom_leaves_input) => {
  const { id = null, ...data } = custom_leaves_input ?? {};

  return {
    customLeaveSettingID: id,
    allow_custom_leave: data?.allow_custom_leave,
    bereavement_leave: data?.bereavement_leave,
    maternal_paternal_leave: data?.maternal_paternal_leave,
    hajj_leave: data?.hajj_leave,
    sabbatical_leave: data?.sabbatical_leave,
    study_leave: data?.study_leave,
    marriage_leave: data?.marriage_leave,
    in_lieu_of_work_day_leave: data?.in_lieu_of_work_day_leave,
    military_call_leave: data?.military_call_leave,
  };
};

const normalizeSubmitCustomLeavesInput = ({
  customLeaveSettingID,
  ...data
}) => {
  const input = {
    bereavement_leave: data?.bereavement_leave,
    maternal_paternal_leave: data?.maternal_paternal_leave,
    hajj_leave: data?.hajj_leave,
    sabbatical_leave: data?.sabbatical_leave,
    study_leave: data?.study_leave,
    marriage_leave: data?.marriage_leave,
    in_lieu_of_work_day_leave: data?.in_lieu_of_work_day_leave,
    military_call_leave: data?.military_call_leave,
  };

  return {
    id: customLeaveSettingID,
    allow_custom_leave: Object.values(input).some((v) => v)
      ? data?.allow_custom_leave
      : false,
    ...(!data?.allow_custom_leave
      ? {
          bereavement_leave: false,
          maternal_paternal_leave: false,
          hajj_leave: false,
          sabbatical_leave: false,
          study_leave: false,
          marriage_leave: false,
          in_lieu_of_work_day_leave: false,
          military_call_leave: false,
        }
      : input),
  };
};

/**
 * End of Custom Leaves Input
 *
 * Start of RequestAbility Input
 */

const normalizeFetchRequestAbilityInput = (
  submit_request_ability_input = {}
) => {
  const { id = null, ...data } = submit_request_ability_input ?? {};
  const getVal = (val) => val || "both";

  return {
    requests_ability_id: id,
    annual_emergency_leave: getVal(data?.annual_emergency_leave),
    annual_emergency_half_day: getVal(data?.annual_emergency_half_day),
    unpaid_leave: getVal(data?.unpaid_leave),
    unpaid_half_day: getVal(data?.unpaid_half_day),
    sick_leave: getVal(data?.sick_leave),
    permission: getVal(data?.permission),
  };
};

const normalizeSubmitRequestAbilityInput = ({
  requests_ability_id,
  ...data
}) => {
  const setVal = (bool, val) => (bool ? val : null);

  return {
    id: requests_ability_id,

    annual_emergency_leave: setVal(
      data?.allow_annual_leaves,
      data?.annual_emergency_leave
    ),
    annual_emergency_half_day: setVal(
      data?.allow_annual_leaves,
      data?.annual_emergency_half_day
    ),

    unpaid_leave: setVal(data?.allow_unpaid_leaves, data?.unpaid_leave),
    unpaid_half_day: setVal(data?.allow_unpaid_leaves, data?.unpaid_half_day),

    sick_leave: setVal(data?.allow_sick_leaves, data?.sick_leave),
    permission: setVal(data?.allow_permissions, data?.permission),
  };
};

/**
 * End of RequestAbility Input
 */
