import gql from "graphql-tag";

import {
  tagFragment,
  leadFragment,
  trialFragment,
  commentFragment,
  contactFragment,
  activityFragment,
  reminderFragment,
  paginatorFragment,
  employeeFragment,
} from "../fragments";

/* Reports */

// Leads Breakdown
export const GET_LEADS_BREAKDOWN_REPORT_OPTIONS = gql`
  query getOptions {
    countries: g_countries
    campaigns: facebook_campaigns
    tags(first: 9999) {
      data {
        ...tag
      }
    }
  }
  ${tagFragment}
`;
export const GET_LEADS_BREAKDOWN_STATUS_REPORT = gql`
  query getLeadsStatusReport($input: LeadFilter) {
    leadsStatusReport: leads_report(args: $input) {
      total: leads_count_details {
        lead_count
        fresh_lead_count: fresh_lead_count_with_percentage
        interested_count: interested_count_with_percentage
        unreachable_count: unreachable_count_with_percentage
        not_interested_count: not_interested_count_with_percentage
        follow_up_count: follow_up_count_with_percentage
        reviewing_proposal_count: reviewing_proposal_count_with_percentage
        demo_count: demo_count_with_percentage
        trial_count: trial_count_with_percentage
        client_count: client_count_with_percentage
      }
      agents: agent_leads_details {
        id
        name
        lead_count
        total_leads_ids
        fresh_lead_count: fresh_lead_count_with_percentage
        fresh_leads_ids
        interested_count: interested_count_with_percentage
        interested_leads_ids
        unreachable_count: unreachable_count_with_percentage
        unreachable_leads_ids
        not_interested_count: not_interested_count_with_percentage
        not_interested_leads_ids
        follow_up_count: follow_up_count_with_percentage
        follow_up_leads_ids
        reviewing_proposal_count: reviewing_proposal_count_with_percentage
        reviewing_proposal_leads_ids
        demo_count: demo_count_with_percentage
        demo_leads_ids
        trial_count: trial_count_with_percentage
        trial_leads_ids
        client_count: client_count_with_percentage
        client_leads_ids
      }
    }
  }
`;
export const GET_LEADS_BREAKDOWN_STATUS_SUB_STATUS_REPORT = gql`
  query getLeadsSubStatusReport(
    $input: LeadFilter
    $FollowUp: Boolean!
    $Interested: Boolean!
    $Unreachable: Boolean!
    $NotInterested: Boolean!
    $ReviewingProposal: Boolean!
  ) {
    leadsSubStatusReport: leads_by_subStatus_report(args: $input) {
      total: leads_count_by_sub_status_details {
        lead_count

        highly_interested_count: highly_interested_count_with_percentage
          @include(if: $Interested)
        semi_interested_count: semi_interested_count_with_percentage
          @include(if: $Interested)
        low_interested_count: low_interested_count_with_percentage
          @include(if: $Interested)

        First_call_unreachable_count: First_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Second_call_unreachable_count: Second_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Third_call_unreachable_count: Third_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Fourth_call_unreachable_count: Fourth_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Fifth_call_unreachable_count: Fifth_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Sixth_call_unreachable_count: Sixth_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Details_sent_unreachable_count: Details_sent_unreachable_count_with_percentage
          @include(if: $Unreachable)

        misunderstood_not_interested_count: misunderstood_not_interested_count_with_percentage
          @include(if: $NotInterested)
        issues_with_pricing_not_interested_count: issues_with_pricing_not_interested_count_with_percentage
          @include(if: $NotInterested)
        client_is_using_another_system_not_interested_count: client_is_using_another_system_not_interested_count_with_percentage
          @include(if: $NotInterested)
        system_is_not_fulfilling_client_requirements_not_interested_count: system_is_not_fulfilling_client_requirements_not_interested_count_with_percentage
          @include(if: $NotInterested)
        others_not_interested_count: others_not_interested_count_with_percentage
          @include(if: $NotInterested)

        requested_by_client_follow_up_count: requested_by_client_follow_up_count_with_percentage
          @include(if: $FollowUp)
        busy_at_the_moment_follow_up_count: busy_at_the_moment_follow_up_count_with_percentage
          @include(if: $FollowUp)

        in_progress_reviewing_proposal_count: in_progress_reviewing_proposal_count_with_percentag
          @include(if: $ReviewingProposal)
        accepted_proposal_reviewing_proposal_count: accepted_proposal_reviewing_proposal_count_with_percentag
          @include(if: $ReviewingProposal)
        rejected_proposal_reviewing_proposal_count: rejected_proposal_reviewing_proposal_count_with_percentag
          @include(if: $ReviewingProposal)
      }

      agents: agent_leads_by_sub_status_details {
        id
        name
        lead_count
        total_leads_ids

        highly_interested_count: highly_interested_count_with_percentage
          @include(if: $Interested)
        semi_interested_count: semi_interested_count_with_percentage
          @include(if: $Interested)
        low_interested_count: low_interested_count_with_percentage
          @include(if: $Interested)

        highly_interested_leads_ids @include(if: $Interested)
        semi_interested_leads_ids @include(if: $Interested)
        low_interested_leads_ids @include(if: $Interested)

        First_call_unreachable_count: First_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Second_call_unreachable_count: Second_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Third_call_unreachable_count: Third_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Fourth_call_unreachable_count: Fourth_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Fifth_call_unreachable_count: Fifth_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Sixth_call_unreachable_count: Sixth_call_unreachable_count_with_percentage
          @include(if: $Unreachable)
        Details_sent_unreachable_count: Details_sent_unreachable_count_with_percentage
          @include(if: $Unreachable)

        First_call_leads_ids @include(if: $Unreachable)
        Second_call_leads_ids @include(if: $Unreachable)
        Third_call_leads_ids @include(if: $Unreachable)
        Fourth_call_leads_ids @include(if: $Unreachable)
        Fifth_call_leads_ids @include(if: $Unreachable)
        Sixth_call_leads_ids @include(if: $Unreachable)
        Details_sent_leads_ids @include(if: $Unreachable)

        misunderstood_not_interested_count: misunderstood_not_interested_count_with_percentage
          @include(if: $NotInterested)
        issues_with_pricing_not_interested_count: issues_with_pricing_not_interested_count_with_percentage
          @include(if: $NotInterested)
        client_is_using_another_system_not_interested_count: client_is_using_another_system_not_interested_count_with_percentage
          @include(if: $NotInterested)
        system_is_not_fulfilling_client_requirements_not_interested_count: system_is_not_fulfilling_client_requirements_not_interested_count_with_percentage
          @include(if: $NotInterested)
        others_not_interested_count: others_not_interested_count_with_percentage
          @include(if: $NotInterested)

        misunderstood_leads_ids @include(if: $NotInterested)
        issues_with_pricing_leads_ids @include(if: $NotInterested)
        client_is_using_another_system_leads_ids @include(if: $NotInterested)
        system_is_not_fulfilling_client_requirements_leads_ids
          @include(if: $NotInterested)
        others_leads_ids @include(if: $NotInterested)

        requested_by_client_follow_up_count: requested_by_client_follow_up_count_with_percentage
          @include(if: $FollowUp)
        busy_at_the_moment_follow_up_count: busy_at_the_moment_follow_up_count_with_percentage
          @include(if: $FollowUp)

        requested_by_client_leads_ids @include(if: $FollowUp)
        busy_at_the_moment_leads_ids @include(if: $FollowUp)

        in_progress_reviewing_proposal_count: in_progress_reviewing_proposal_count_with_percentag
          @include(if: $ReviewingProposal)
        accepted_proposal_reviewing_proposal_count: accepted_proposal_reviewing_proposal_count_with_percentag
          @include(if: $ReviewingProposal)
        rejected_proposal_reviewing_proposal_count: rejected_proposal_reviewing_proposal_count_with_percentag
          @include(if: $ReviewingProposal)

        in_progress_leads_ids @include(if: $ReviewingProposal)
        accepted_proposal_leads_ids @include(if: $ReviewingProposal)
        rejected_proposal_leads_ids @include(if: $ReviewingProposal)
      }
    }
  }
`;

// Pipeline
export const GET_PIPELINE_MONTHLY_REPORT = gql`
  query getPipelineMonthlyReport($from: Date!, $to: Date!) {
    pipelineMonthlyReport: leadsCountsByMonth(from: $from, to: $to) {
      monthYear
      Client
      client_manual
      Trial
      Demo
      client_perc
      client_manual_perc
      trial_perc
      demo_perc
      new_users
      new_users_by_crm
      demo_leads
      trial_leads
      client_leads
      client_manual_company
      monthly_subscription_value
      yearly_subscription_value
      price_per_user
    }
  }
`;
export const GET_PIPELINE_QUARTERLY_REPORT = gql`
  query getPipelineQuarterlyReport($from: Date!, $to: Date!) {
    pipelineQuarterlyReport: leadsCountsByQuarter(from: $from, to: $to) {
      Quarter
      Trial
      trial_perc
      Demo
      demo_perc
      Client
      client_perc
      client_manual
      client_manual_perc
      new_users
      new_users_by_crm
      demo_leads
      trial_leads
      client_leads
      client_manual_company
      monthly_subscription_value
      yearly_subscription_value
      price_per_user
      months {
        Month
        Trial
        trial_perc
        Demo
        demo_perc
        Client
        client_perc
        client_manual
        client_manual_perc
        new_users
        new_users_by_crm
        demo_leads
        trial_leads
        client_leads
        client_manual_company
        monthly_subscription_value
        yearly_subscription_value
        price_per_user
      }
    }
  }
`;
export const GET_PIPELINE_CUSTOM_REPORT = gql`
  query getPipelineCustomReport($from: Date!, $to: Date!) {
    pipelineCustomReport: leadsCountsByCustom(from: $from, to: $to) {
      Client
      client_manual
      Trial
      Demo
      new_users
      new_users_by_crm
      demo_leads
      trial_leads
      client_leads
      client_manual_company
      monthly_subscription_value
      yearly_subscription_value
      price_per_user
    }
  }
`;

// Agent Pipeline
export const GET_AGENT_PIPELINE_MONTHLY_REPORT = gql`
  query getAgentPipelineMonthlyReport(
    $to: Date!
    $from: Date!
    $agents: [ID]
    $type: String! = "active"
  ) {
    agentPipelineMonthlyReport: agentCountsByMonth(
      from: $from
      to: $to
      users: $agents
      type: $type
    ) {
      Agent
      Trial
      Demo
      Client
      new_users
      demoLeads
      trialLeads
      clientLeads
      months {
        Month
        Trial
        trial_perc
        Demo
        demo_perc
        Client
        client_perc
        new_users
        demoLeads
        trialLeads
        clientLeads
      }
    }
  }
`;
export const GET_AGENT_PIPELINE_QUARTERLY_REPORT = gql`
  query getAgentPipelineQuarterlyReport(
    $to: Date!
    $from: Date!
    $agents: [ID]
    $type: String! = "active"
  ) {
    agentPipelineQuarterlyReport: agentCountsByQuarter(
      from: $from
      to: $to
      users: $agents
      type: $type
    ) {
      Agent
      Trial
      Demo
      Client
      new_users
      demoLeads
      trialLeads
      clientLeads
      quarters {
        Quarter
        Trial
        trial_perc
        Demo
        demo_perc
        Client
        client_perc
        new_users
        demoLeads
        trialLeads
        clientLeads
      }
    }
  }
`;
export const GET_AGENT_PIPELINE_CUSTOM_REPORT = gql`
  query getAgentPipelineCustomReport(
    $to: Date!
    $from: Date!
    $agents: [ID]
    $type: String! = "active"
  ) {
    agentPipelineCustomReport: agentCountsByCustom(
      from: $from
      to: $to
      users: $agents
      type: $type
    ) {
      Agent
      Trial
      Demo
      Client
      new_users
      demoLeads
      trialLeads
      clientLeads
    }
  }
`;

// Agent Leads Status
export const GET_AGENT_LEADS_STATUS_REPORT = gql`
  query getAgentLeadsStatusReport($from: Date, $to: Date, $agents: [ID]) {
    agent_leads_status_report: agent_leads_report(
      to: $to
      from: $from
      assigned_to: $agents
    ) {
      id
      name
      lead_count
      fresh_lead_count_with_percentage
      interested_count_with_percentage
      unreachable_count_with_percentage
      not_interested_count_with_percentage
      follow_up_count_with_percentage
      reviewing_proposal_count_with_percentage
      demo_count_with_percentage
      trial_count_with_percentage
      client_count_with_percentage
      clients_demo_ratio
    }
  }
`;

// Agent Activities
export const GET_AGENT_ACTIVITIES_REPORT = gql`
  query getAgentActivitiesReport($from: Date, $to: Date, $agents: [ID]) {
    agent_activities_report(from: $from, to: $to, assigned_to: $agents) {
      id
      name
      total_activities
      call_count
      message_count
      meeting_count
      demo_count
      email_count
      proposal_count
      calls_per_day
      messages_per_day
      meetings_per_day
      demos_per_day
      emails_per_day
      proposals_per_day
      activities_per_day
    }
  }
`;

// Agent Unreachable
export const GET_AGENT_UNREACHABLE_REPORT = gql`
  query getAgentUnreachableReport(
    $to: Date!
    $from: Date!
    $agents: [ID]
    $ex_days: Int
  ) {
    agentUnreachableReport: agentLeadUnreachableCounts(
      to: $to
      from: $from
      users: $agents
      ex_days: $ex_days
    ) {
      Agent
      avg_time
      leadsCount
      leads_id
    }
  }
`;

// Agent Actions
export const GET_AGENT_ACTIONS_REPORT = gql`
  query getAgentActionsReport(
    $to: Date!
    $from: Date!
    $agents: [ID]
    $ex_days: Int
  ) {
    agentActions: agentLeadAfterAssignCounts(
      to: $to
      from: $from
      users: $agents
      ex_days: $ex_days
    ) {
      Agent
      avg_time
      leadsCount
      leads_id
    }
  }
`;

/* Leads */

export const fetchLeadQuery = gql`
  query fetchLead($id: ID) {
    lead(id: $id) {
      ...lead
      contacts {
        ...contact
      }
    }
  }
  ${leadFragment}
  ${contactFragment}
`;

export const leadsListQuery = gql`
  query leadsList(
    $input: LeadFilter
    $page: Int
    $first: Int!
    $orderBy: [QueryLeadsOrderByRelationOrderByClause!]
  ) {
    leads(args: $input, page: $page, first: $first, orderBy: $orderBy) {
      data {
        ...lead
        lead_assign_date
        nextReminder {
          type
          due_date
        }
        contacts {
          ...contact
        }
        comments {
          ...comment
        }
        activities {
          ...activity
        }
        latestActivity {
          ...activity
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
    campaigns: facebook_campaigns
    tags(first: 9999) {
      data {
        ...tag
      }
    }
  }
  ${leadFragment}
  ${commentFragment}
  ${contactFragment}
  ${activityFragment}
  ${paginatorFragment}
`;
export const leadsListOptionsQuery = gql`
  query leadsListOptions {
    countries: g_countries
  }
`;

export const leadFormQuery = gql`
  query leadForm($id: ID, $isLeadSkip: Boolean!) {
    lead(id: $id) @skip(if: $isLeadSkip) {
      ...lead
      contacts {
        ...contact
      }
    }

    # Options
    industries
    countries: g_countries
    campaigns: facebook_campaigns
    tags(first: 9999) {
      data {
        ...tag
      }
    }
    users: saas_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${tagFragment}
  ${leadFragment}
  ${contactFragment}
`;

export const setLeadsAgentFormQuery = gql`
  query fetchActivitiesFiltersOptions {
    users: saas_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;

export const getActiveSaasUsersMenu = gql`
  query fetchActiveSaasUsersMenu {
    users: active_saas_users_menu {
      id
      name
    }
  }
`;

/* Activities */

export const activitiesListQuery = gql`
  query activitiesList(
    $page: Int
    $to: Date
    $from: Date
    $first: Int!
    $lead_id: Int
    $type: String
    $status: String
    $f_type: String
    $assigned_to: Int
  ) {
    activities(
      to: $to
      from: $from
      page: $page
      type: $type
      first: $first
      status: $status
      f_type: $f_type
      lead_id: $lead_id
      assigned_to: $assigned_to
    ) {
      data {
        ...activity
        contact {
          ...contact
        }
        comments {
          ...comment
        }
        user {
          name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${activityFragment}
  ${paginatorFragment}
`;
// export const activitiesOptionsQuery = gql`
//   query activitiesListOptions {
//     users: saas_names(first: 9999, haveLeads: 1) {
//       data {
//         id
//         name
//       }
//     }
//   }
// `;

export const activityFormQuery = gql`
  query activityForm($id: ID, $isActivitySkip: Boolean!) {
    activity(id: $id) @skip(if: $isActivitySkip) {
      ...activity
      comments {
        ...comment
      }
      contact {
        ...contact
      }
    }

    # Options
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${activityFragment}
`;

/* Reminders */

export const remindersListQuery = gql`
  query remindersList(
    $to: Date
    $page: Int
    $from: Date
    $first: Int!
    $lead_ids: [ID]
    $type: String
    $f_type: String
    $ff_type: String
    $assigned_to: ID
  ) {
    reminders(
      to: $to
      from: $from
      type: $type
      page: $page
      first: $first
      f_type: $f_type
      ff_type: $ff_type
      lead_ids: $lead_ids
      assigned_to: $assigned_to
    ) {
      data {
        ...reminder
        comments {
          ...comment
        }
        user {
          name
        }
        contact {
          ...contact
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${reminderFragment}
  ${paginatorFragment}
`;
// export const remindersListOptionsQuery = gql`
//   query remindersListOptions {
//     users: saas_names(first: 9999, haveLeads: 1) {
//       data {
//         id
//         name
//       }
//     }
//   }
// `;

export const reminderFormQuery = gql`
  query reminderForm($id: ID, $isReminderSkip: Boolean!) {
    reminder(id: $id) @skip(if: $isReminderSkip) {
      ...reminder
      comments {
        ...comment
      }
      contact {
        ...contact
      }
    }

    # Options
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${reminderFragment}
`;

/* Trials */

export const trialsListQuery = gql`
  query trialsList(
    $to: Date
    $page: Int
    $from: Date
    $first: Int!
    $user_id: ID
    $lead_ids: [ID]
    $status: String
    $company: String
    $assigned_to: ID
  ) {
    trials(
      to: $to
      from: $from
      page: $page
      first: $first
      status: $status
      company: $company
      user_id: $user_id
      lead_ids: $lead_ids
      assigned_to: $assigned_to
    ) {
      data {
        ...trial
        lead {
          id
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${trialFragment}
  ${paginatorFragment}
`;
export const trialsListOptionsQuery = gql`
  query trialsListOptions {
    users: saas_names(first: 9999, haveLeads: 1) {
      data {
        id
        name
      }
    }
  }
`;

export const trialFormQuery = gql`
  query trialForm($id: ID, $isTrialSkip: Boolean!) {
    trial(id: $id) @skip(if: $isTrialSkip) {
      ...trial
      lead {
        id
        name
      }
    }

    # Options
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
    managers: saas_names(first: 9999, type: "Trial") {
      data {
        id
        name
      }
    }
  }
  ${trialFragment}
`;

/* Agents */

export const agentListQuery = gql`
  query agentList($first: Int!, $page: Int!) {
    agents: saas_names(first: $first, page: $page, haveLeads: 1) {
      data {
        ...employeeProfile
        emp_multiple_sign_ins: card_details {
          sign_in_time
          sign_out_time
          sign_in_location_name
          sign_out_location_name
          status
          work_timing_name
        }
        attendance_profile {
          name
          permission_leaves_break_setting {
            annualLeaveSetting {
              allow_annual_leaves
              allowed_emergency_leaves
            }
            sickLeaveSetting {
              sick_leaves
            }
          }
          activeWorkTiming {
            overtime_permissible
            name
          }
        }
        employee {
          relevantAttendanceTypeConfiguration {
            type
            workGroup {
              name
              managers {
                id
                name
              }
            }
            workTeam {
              name
            }
          }
        }
        emp_multiple_sign_ins: card_details {
          sign_in_time
          sign_out_time
          sign_in_location_name
          sign_out_location_name
          status
          work_timing_name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${employeeFragment}
  ${paginatorFragment}
`;

/* Calendar */

export const calendarQuery = gql`
  query calendar($from: Date, $to: Date, $assigned_to: ID) {
    crm_calendar(from: $from, to: $to, assigned_to: $assigned_to) {
      type
      info
      date
    }
  }
`;

export const calendarEventQuery = gql`
  query calendarEvent(
    $id: ID
    $isTrial: Boolean! = false
    $isActivity: Boolean! = false
    $isReminders: Boolean! = false
  ) {
    activity(id: $id) @include(if: $isActivity) {
      status
      contact {
        name
      }
      lead {
        id
        name
        assignedTo {
          name
        }
      }
    }
    reminder(id: $id) @include(if: $isReminders) {
      status
      due_date
      contact {
        name
      }
      lead {
        id
        name
        assignedTo {
          name
        }
      }
    }
    trial(id: $id) @include(if: $isTrial) {
      subscription {
        end_date
        start_date
        limit: employees_limit
        plan {
          name
        }
        company {
          businessPartners {
            user {
              name
            }
          }
        }
      }
    }
  }
`;

/* Dashboard */

export const dashboardCRMQuery = gql`
  query dashboardCRM(
    $to: Date
    $from: Date
    $reminderAgent: ID = null
    $activityAgent: Int = null
    $activityScope: String = "All"
    $remindersScope: String = "All"
    $isReminders: Boolean! = false
    $isActivities: Boolean! = false
  ) {
    activities(
      to: $to
      from: $from
      first: 9999
      f_type: $activityScope
      assigned_to: $activityAgent
    ) @include(if: $isActivities) {
      data {
        id
        type
        date
        notes
        lead {
          id
          name
          assignedTo {
            name
          }
        }
        contact {
          ...contact
        }
      }
    }
    reminders(
      to: $to
      from: $from
      first: 9999
      ff_type: $remindersScope
      assigned_to: $reminderAgent
    ) @include(if: $isReminders) {
      data {
        id
        type
        notes
        status
        due_date
        lead {
          id
          name
          assignedTo {
            name
          }
        }
        contact {
          ...contact
        }
      }
    }
  }
  ${contactFragment}
`;

export const dashboardCRMOptionsQuery = gql`
  query dashboardCRMOptions {
    agents: saas_names(first: 9999, haveLeads: 1) {
      data {
        id
        name
      }
    }
  }
`;

/* Others */

export const getCitiesCountriesQuery = gql`
  query getCitiesCountries($country: String) {
    cities: g_cities(name: $country)
  }
`;

export const fetchLeadContactQuery = gql`
  query fetchLeadContact($id: ID) {
    lead(id: $id) {
      contacts {
        ...contact
      }
    }
  }
  ${contactFragment}
`;
