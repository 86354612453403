import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CheckboxBooleanForm, DateTimePickerForm, BSelect } from "form-builder";

import { useTranslation } from "react-i18next";
import { onInputResetAction } from "../../Store/Actions";
import LimitWorkTimingsSection from "../LimitWorkTimingsSection";

const AttSignInSettings = (props) => {
  const { t } = useTranslation();

  const { formProps } = props;

  // redux hooks
  const dispatch = useDispatch();
  const work_timing_profile_type = useSelector(
    (state) => state.super[formProps?.formName].work_timing_profile_type
  );
  const sign_in_from_office_geolocation = useSelector(
    (state) => state.super[formProps?.formName].sign_in_from_office_geolocation
  );
  const same_policies = useSelector(
    (state) => state.super[formProps?.formName]?.same_policies
  );
  const sign_in_req = useSelector(
    (state) => state.super[formProps?.formName]?.sign_in_req
  );

  useEffect(() => {
    if (
      work_timing_profile_type === "half" ||
      sign_in_from_office_geolocation === 0
    ) {
      dispatch(
        onInputResetAction(
          formProps?.formName,
          "allow_locations_custom_override"
        )
      );
    }
  }, [work_timing_profile_type, sign_in_from_office_geolocation]);

  return (
    <>
      <div className="">
        <CheckboxBooleanForm
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
          {...formProps}
          options={["Apply Sign In with Facial Recognition"]}
          name="face_rekognition"
          validationName="input.face_rekognition"
          type="checkbox"
        />
        {/* <CheckboxBooleanForm
          dependOn="face_rekognition"
          dependancyType="equal"
          dependancyValue={[1]}
          {...formProps}
          options={["Apply Liveness Detection with Facial Recognition"]}
          name="apply_facial_challenge_to_sign_in"
          validationName="input.apply_facial_challenge_to_sign_in"
          type="checkbox"
          containerStyle="ml-4 mt-1"
        /> */}
        <CheckboxBooleanForm
          dependOn="face_rekognition"
          dependancyType="equal"
          dependancyValue={[1, "1"]}
          {...formProps}
          options={["Apply Facial Recognition On Employees working from home"]}
          name="home_face_rekognition"
          validationName="input.home_face_rekognition"
          type="checkbox"
          containerStyle="ml-4 mt-1"
        />
      </div>
      <div className="">
        <CheckboxBooleanForm
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
          {...formProps}
          options={["Allow Employee To Sign In From Any Location"]}
          name="remote_sign_in"
          validationName="input.remote_sign_in"
          type="checkbox"
        />
        <div className="sub-container-style">
          <CheckboxBooleanForm
            dependOn="remote_sign_in"
            dependancyType="equal"
            dependancyValue={[0]}
            {...formProps}
            options={["Allow Sign In From Office IP Address"]}
            name="sign_in_from_office_ips"
            validationName="input.sign_in_from_office_ips"
            type="checkbox"
          />

          <CheckboxBooleanForm
            dependOn="remote_sign_in"
            dependancyType="equal"
            dependancyValue={[0]}
            {...formProps}
            options={["Allow Sign In From Office Location"]}
            name="sign_in_from_office_geolocation"
            validationName="input.sign_in_from_office_geolocation"
            type="checkbox"
          />

          {sign_in_from_office_geolocation === 1 &&
          work_timing_profile_type !== "half" ? (
            <CheckboxBooleanForm
              {...formProps}
              options={["Allow Custom Locations In Employee Profile"]}
              name="allow_locations_custom_override"
              validationName="input.allow_locations_custom_override"
              type="checkbox"
            />
          ) : null}

          <CheckboxBooleanForm
            dependOn="sign_in_from_office_geolocation"
            dependancyType="equal"
            dependancyValue={[1]}
            {...formProps}
            options={["Allow Sign In Outside Specified Locations"]}
            name="allow_sign_in_outside_specified_locations"
            validationName="input.allow_sign_in_outside_specified_locations"
            type="checkbox"
          />
        </div>
      </div>

      <div className=" d-flex flex-row flex-wrap justify-content-between">
        <CheckboxBooleanForm
          {...formProps}
          options={["Send A Sign In Reminder"]}
          name="sign_in_reminder"
          type="checkbox"
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <DateTimePickerForm
          timeOnly
          {...formProps}
          dependOn="sign_in_reminder"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          name="sign_in_reminder_time"
          validationName="input.sign_in_reminder_time"
        />
      </div>

      <h5 className="content-header">{t("sign out")}</h5>
      <div className="">
        <CheckboxBooleanForm
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
          {...formProps}
          options={["Apply Sign Out Same as Sign In"]}
          name="same_policies"
          validationName="input.same_policies"
          type="checkbox"
        />
      </div>

      <div className={same_policies === 1 ? "d-none" : ``}>
        <CheckboxBooleanForm
          dependOn="same_policies"
          dependancyType="equal"
          dependancyValue={[0]}
          {...formProps}
          options={["Allow Employee To Sign Out From Any Location"]}
          name="remote_sign_out"
          validationName="input.remote_sign_out"
          type="checkbox"
        />
        <div className="sub-container-style">
          <CheckboxBooleanForm
            dependOn="remote_sign_out"
            dependancyType="equal"
            dependancyValue={[0]}
            {...formProps}
            options={["Allow Sign Out From Office IP Address"]}
            name="sign_out_from_office_ips"
            validationName="input.sign_out_from_office_ips"
            type="checkbox"
          />

          <CheckboxBooleanForm
            dependOn="remote_sign_out"
            dependancyType="equal"
            dependancyValue={[0]}
            {...formProps}
            options={["Allow Sign Out From Office Location"]}
            name="sign_out_from_office_geolocation"
            validationName="input.sign_out_from_office_geolocation"
            type="checkbox"
          />

          <CheckboxBooleanForm
            dependOn="sign_out_from_office_geolocation"
            dependancyType="equal"
            dependancyValue={[1]}
            {...formProps}
            options={["Allow Sign Out Custom Locations In Employee Profile"]}
            name="sign_out_locations_custom_override"
            validationName="input.sign_out_locations_custom_override"
            type="checkbox"
          />

          <CheckboxBooleanForm
            dependOn="sign_out_from_office_geolocation"
            dependancyType="equal"
            dependancyValue={[1]}
            {...formProps}
            options={["Allow Sign out Outside Specified Locations"]}
            name="allow_sign_out_outside_specified_locations"
            validationName="input.allow_sign_out_outside_specified_locations"
            type="checkbox"
          />
        </div>
      </div>

      <div className=" d-flex flex-row flex-wrap justify-content-between">
        <CheckboxBooleanForm
          {...formProps}
          options={["Send A Sign Out Reminder"]}
          name="sign_out_reminder"
          validationName="input.sign_out_reminder"
          type="checkbox"
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <DateTimePickerForm
          timeOnly
          {...formProps}
          dependOn="sign_out_reminder"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          name="sign_out_reminder_time"
          validationName="input.sign_out_reminder_time"
        />
      </div>

      {/* Start of limit work timings section */}
      {+sign_in_req ? <LimitWorkTimingsSection formProps={formProps} /> : null}
      {/* End of limit work timings section */}
    </>
  );
};

export default AttSignInSettings;
