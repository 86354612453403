import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchCompanyExpensesListAction,
  onInputResetAction,
  showCompanyExpensesModalAction,
  fetchCompanyExpensesSubCategoriesAction,
  fetchTotalExpenseBalanceAction,
  exportCompanyExpensesAction,
  deleteCompanyExpenseAction,
  fetchPettyCashCurrenciesAction,
} from "../../Store/Actions";
import { BSelect, DateTimePickerForm } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CompanyExpensesModal from "../../Components/CompanyExpensesModal";
import ShowMoreText from "react-show-more-text";
import HelperFns from "../../Helpers/HelperFns";
import {
  EditIconButton,
  RemoveIconButton,
  DownloadFileIconButton,
} from "../../Components/IconButtonWithTooltip";
import { Tooltip } from "@mui/material";

const dataTableRef = "companyExpensesList";

const CompanyExpenses = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const user = useSelector((state) => state?.auth?.userProfile);
  const companyExpensesList = useSelector(
    (state) => state?.finances[dataTableRef]
  );
  const deleteCompanyExpenseLoading = useSelector(
    (state) => state?.finances?.deleteCompanyExpenseLoading
  );
  const isExportCompanyExpensesLoading = useSelector(
    (state) => state?.finances?.exportCompanyExpensesLoading
  );
  const expenseTotalBalanceData = useSelector(
    (state) => state?.finances?.expenseTotalBalanceData
  );
  const companyExpenseCategoryData = useSelector(
    (state) => state?.finances?.companyExpenseCategoryData
  );
  const companyExpenseSubCategoryData = useSelector(
    (state) => state?.finances?.companyExpenseSubCategoryData
  );
  const pettyCashCurrenciesOptionsData = useSelector(
    (state) => state?.finances?.pettyCashCurrenciesOptionsData
  );
  const avaliablePettyCashEmployeesFilterOptions = useSelector(
    (state) => state?.finances?.avaliablePettyCashEmployeesFilterOptions
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(
      fetchCompanyExpensesListAction({
        ref: dataTableRef,
        companyExpensesList,
      })
    );
    dispatch(
      fetchTotalExpenseBalanceAction({
        ref: dataTableRef,
        companyExpensesList,
      })
    );
    dispatch(
      fetchPettyCashCurrenciesAction({
        emp_id: "",
        type: "expenses_list",
      })
    );
    return () => {
      dispatch(onInputResetAction(dataTableRef, "emp_id"));
      dispatch(onInputResetAction(dataTableRef, "currency_id"));
      dispatch(onInputResetAction(dataTableRef, "category_id"));
      dispatch(onInputResetAction(dataTableRef, "sub_category_id"));
      dispatch(onInputResetAction(dataTableRef, "office_id"));
      dispatch(onInputResetAction(dataTableRef, "from"));
      dispatch(onInputResetAction(dataTableRef, "to"));
    };
  }, []);

  // start of handling sub gatgory filter option according to what is chosen in category filter
  const handleSubCatgoryFilter = () => {
    dispatch(
      fetchCompanyExpensesSubCategoriesAction({
        ref: dataTableRef,
        companyExpensesList,
      })
    );
  };

  const useDidSubCatgoryUpdate = useDidUpdateEffect(handleSubCatgoryFilter, [
    companyExpensesList.category_id,
  ]);
  // End of handling sub gatgory filter option according to what is chosen in category filter

  // start of handling total expense balance according to what is chosen in any avaliable filter
  const handleTotalExpenseBalance = () => {
    dispatch(
      fetchTotalExpenseBalanceAction({
        ref: dataTableRef,
        companyExpensesList,
      })
    );
  };

  const useDidOfficeFilterUpdate = useDidUpdateEffect(
    handleTotalExpenseBalance,
    [
      companyExpensesList.emp_id,
      companyExpensesList.currency_id,
      companyExpensesList.category_id,
      companyExpensesList.sub_category_id,
      companyExpensesList.office_id,
      companyExpensesList.from,
      companyExpensesList.to,
    ]
  );
  // End of handling total expense balance according to what is chosen in any avaliable filter

  const handleFilter = () => {
    dispatch(
      fetchCompanyExpensesListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...companyExpensesList,
          pagination: {
            ...companyExpensesList.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    companyExpensesList.emp_id,
    companyExpensesList.currency_id,
    companyExpensesList.category_id,
    companyExpensesList.sub_category_id,
    companyExpensesList.office_id,
    companyExpensesList.from,
    companyExpensesList.to,
  ]);

  /* ↓ Helpers ↓ */

  const handlePaginate = (
    page = companyExpensesList.pagination.currentPage
  ) => {
    dispatch(
      fetchCompanyExpensesListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...companyExpensesList,
          pagination: {
            ...companyExpensesList.pagination,
            currentPage: page,
          },
        },
      })
    );
  };

  // handling add company expenses modal
  const handleAddCompanyExpenses = () => {
    let data = {
      id: null,
      office_id: HelperFns.checkPrivileges({
        allowBP: true,
        privileges: [Privilages.VIEW_THE_EXPENSES_OF_ALL_OFFICES],
      })
        ? ""
        : user?.office.id,
    };
    dispatch(
      showCompanyExpensesModalAction({
        data,
        ref: "companyExpensesModalActions",
        formName: "companyExpensesModal",
      })
    );
  };

  // handleing edit company expenses modal
  const handleEditCompanyExpenses = (row) => {
    let data = {
      id: row?.id,
      emp_id: row?.emp_id,
      office_id: row?.office?.id,
      category_id: row?.category?.id,
      sub_category_id: row?.sub_category?.id,
      details: row?.details,
      type: row?.type,
      date: row?.date,
      amount: row?.amount.toString(),
      currency_id: row?.currency?.id,
      on_behalf_of: row?.type == "expense" ? 1 : 0,
      attachments: row?.files,
    };
    dispatch(
      showCompanyExpensesModalAction({
        data,
        ref: "companyExpensesModalActions",
        formName: "companyExpensesModal",
      })
    );
  };

  // handle export company expenses
  const handleExportCompanyExpenses = () => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_export_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willExport) => {
      if (willExport) {        
        dispatch(exportCompanyExpensesAction(companyExpensesList));
      }
    });
  };

  // handle delete company expense record
  const handleDelete = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteCompanyExpenseAction(row?.id));
      }
    });
  };

  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  const columns = [
    {
      name: t("category"),
      wrap: true,
      sortable: false,
      grow: 1.9,
      cell: (row) => (
        <div className="d-flex">
          <b>{row?.category?.name}</b>
          {row?.category && row?.sub_category ? (
            <div className="mx-2">-</div>
          ) : (
            ""
          )}
          {row?.sub_category ? row?.sub_category?.name : ""}
        </div>
      ),
    },
    {
      name: t("offices"),
      wrap: true,
      sortable: false,
      grow: 1.7,
      cell: (row) => <>{row?.office?.name}</>,
    },
    {
      name: t("description"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {row?.details}
        </ShowMoreText>
      ),
    },
    {
      name: t("employee"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) =>
        row?.emp_id != "0" ? (
          <CanViewEmployeeProfile
            allowBP
            directManger={row?.user?.manager?.id}
            copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
            altChildren={<div className="col pl-0">{row?.employee_name}</div>}
          >
            <div className="col pl-0">
              <Link
                className="employee-name"
                to={`/employees/employee-profile/${row?.emp_id}`}
              >
                {row?.employee_name}
              </Link>
            </div>
          </CanViewEmployeeProfile>
        ) : (
          <>{t("Office Expense")}</>
        ),
    },
    {
      name: t("created by"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => <>{row?.added_by?.name}</>,
    },
    {
      name: t("amount"),
      wrap: true,
      sortable: false,
      grow: 0.7,
      cell: (row) => (
        <div className="d-flex amount_currency_wrapper_style">
          <div className="pr-1">{roundToTwo(row?.amount)}</div>
          <span>{row?.currency?.symbol}</span>
        </div>
      ),
    },
    {
      name: t("incurred at"),
      wrap: true,
      sortable: false,
      grow: 0.7,
      cell: (row) => (
        <>
          {row?.date != null ? moment(row?.date).format("DD - MM - YYYY") : ""}
        </>
      ),
    },
    {
      name: t("Attachments"),
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => (
        <div className="row data-table-action">
          {row?.files?.map((file) => (
            <div className="col-4">
              <DownloadFileIconButton key={file?.id} href={file?.path} />
            </div>
          ))}
        </div>
      ),
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_COMPANY_EXPENSES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<EditIconButton />}
          >
            <EditIconButton onClick={() => handleEditCompanyExpenses(row)} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.DELETE_COMPANY_EXPENSES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<RemoveIconButton />}
          >
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <div className="company_expenses_wrapper_style">
      {isExportCompanyExpensesLoading || deleteCompanyExpenseLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row mb-1 align-items-baseline">
        <div className="col-12 col-xl-6">
          <div className="row align-items-baseline">
            <HasPrivileges
              reqireMain={[Privilages.VIEW_THE_EXPENSES_OF_ALL_OFFICES]}
              allowBP
            >
              <div className="col-12 col-xl-6 pb-1 pb-xl-0">
                <BSelect
                  reducer="finances"
                  formName={dataTableRef}
                  name="office_id"
                  optionLabel="name"
                  optionValue="id"
                  options={[
                    { name: "all offices", id: null },
                    ...user?.offices,
                  ]}
                  keepDefaultStyle
                  placeholder={t("offices")}
                  icon="office"
                  skipLocalization
                />
              </div>
            </HasPrivileges>

            <div className="col pb-1 pb-xl-0">
              <DateTimePickerForm
                name="from"
                placeholder="from"
                reducer="finances"
                containerStyle=" "
                formName={dataTableRef}
                requestFormat="YYYY-MM-DD"
              />
            </div>

            <div className="col-12 col-xl-3 pb-4 pb-xl-0">
              <DateTimePickerForm
                name="to"
                placeholder="to"
                reducer="finances"
                containerStyle=" "
                formName={dataTableRef}
                requestFormat="YYYY-MM-DD"
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-6">
          <div className="row align-items-baseline">
            <div className="col pb-1 pb-xl-0">
              <BSelect
                reducer="finances"
                formName={dataTableRef}
                name="category_id"
                optionLabel="name"
                optionValue="id"
                options={companyExpenseCategoryData}
                keepDefaultStyle
                isClearable
                placeholder={t("select category")}
                icon="type"
              />
            </div>

            {companyExpenseSubCategoryData.length === 0 ? (
              ""
            ) : (
              <div className="col-12 col-xl-6 pb-1">
                <BSelect
                  reducer="finances"
                  formName={dataTableRef}
                  name="sub_category_id"
                  optionLabel="name"
                  optionValue="id"
                  options={companyExpenseSubCategoryData}
                  keepDefaultStyle
                  isClearable
                  placeholder={t("select sub-category")}
                  icon="type"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row mb-4 align-items-baseline">
        <div className="col-12 col-xl-6">
          <div className="row align-items-baseline">
            <div className="col pb-1 pb-xl-0">
              <div className="d-flex align-items-baseline total_expense_wrapper_style">
                <div className="dark-bg">
                  <h2 className="mb-0">{t("Total Expenses")}</h2>
                </div>

                <div className="d-flex px-4">
                  {expenseTotalBalanceData.map((balance) => (
                    <div className="d-flex px-2">
                      <b>{balance?.currency?.symbol}</b>
                      <p className="px-1">{roundToTwo(balance?.total)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {pettyCashCurrenciesOptionsData.length === 1 ? (
              ""
            ) : (
              <div className="col-12 col-xl-3 pb-1 pb-xl-0">
                <BSelect
                  reducer="finances"
                  name="currency_id"
                  formName={dataTableRef}
                  options={pettyCashCurrenciesOptionsData}
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t("Currency")}
                  isClearable
                  icon="currency"
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-12 col-xl-6">
          <div className="row align-items-center">
            <div className="col-12 col-xl-7 pb-1 pb-xl-0">
              <BSelect
                reducer="finances"
                name="emp_id"
                formName={dataTableRef}
                options={avaliablePettyCashEmployeesFilterOptions}
                keepDefaultStyle
                optionLabel="name"
                optionValue="emp_id"
                containerStyle="my-0 mb-2 mb-lg-0"
                isClearable
                placeholder={t("select_employee")}
                icon="person"
              />
            </div>

            <div className="d-flex justify-content-end col-12 col-xl-5 pb-1 pb-xl-0">
              <HasPrivileges
                reqireMain={[Privilages.EXPORT_EXPENSES]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <span className="mx-2 btn add_new_btn_style export_expense_btn_style">
                    <FontAwesomeIcon icon={faFileDownload} />
                  </span>
                }
              >
                <Tooltip arrow title={t("export")} aria-label={t("export")}>
                  <button
                    className="mx-2 btn add_new_btn_style export_expense_btn_style"
                    type="button"
                    onClick={handleExportCompanyExpenses}
                  >
                    <FontAwesomeIcon icon={faFileDownload} />
                  </button>
                </Tooltip>
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[Privilages.ADD_EDIT_COMPANY_EXPENSES]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <button
                    className="btn add_new_btn_style"
                    type="button"
                    disabled
                  >
                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                    {t("Add Expense")}
                  </button>
                }
              >
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={handleAddCompanyExpenses}
                  disabled={companyExpensesList.isLoading}
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  {t("Add Expense")}
                </button>
              </HasPrivileges>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={companyExpensesList.data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="finances"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={companyExpensesList.isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of company expenses modal */}
      <CompanyExpensesModal />
      {/* End of company expenses modal */}
    </div>
  );
};

export default CompanyExpenses;
