import React from "react";
import { useTranslation } from "react-i18next";

import rocket from "../../Assets/img/rocket.svg";
import blueCloud from "../../Assets/img/blueCloud.svg";

import {
  Box,
  Card,
  CardMedia,
  Typography,
  CardActions,
  CardContent,
} from "@mui/material";
import { ColorButton } from "../../Components/Buttons";

const SuccessView = () => {
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        display: "grid",
        minHeight: "100vh",
        textAlign: "center",
        placeItems: "center",
        position: "relative",
        overflowY: "initial",
        background:
          "linear-gradient(0deg, rgba(0, 158, 251, 0.15), rgba(0, 158, 251, 0.15))",
        ".img": {
          top: "-100px",
          position: "absolute",
          left: "calc(50% - 100px)",
        },
      }}
    >
      <Card
        sx={{
          width: 1,
          maxWidth: 550,
          overflow: "initial",
          position: "relative",
          borderRadius: "14px",
          boxShadow: "0px 0px 86px 0px #09090929",
        }}
      >
        <img
          src={rocket}
          width="230"
          height="230"
          className="img"
          alt=""
          aria-hidden="true"
        />
        <CardMedia
          title=""
          aria-hidden="true"
          image={blueCloud}
          sx={{ height: 140 }}
        />
        <CardContent sx={{ mt: 1 }}>
          <Typography
            component="h1"
            variant="h5"
            fontWeight={500}
            gutterBottom
            textTransform="initial"
          >
            {t("Updated Successfully!")}
          </Typography>
          <Typography
            sx={{
              mx: "auto",
              maxWidth: 463,
              color: "#898A8D",
              textTransform: "initial",
            }}
          >
            {t(
              "You’ll start receiving notifications based on your updated preferences shortly."
            )}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", pb: 3 }}>
          <ColorButton
            href="/"
            color="#009EFB"
            variant="contained"
            label={t("go to home")}
            sx={{
              width: 150,
              fontSize: 15,
              fontWeight: 500,
              boxShadow: "0px 0px 19.1px 0px #0D84CA40",
              background:
                "linear-gradient(91.62deg, #009EFB 3.84%, #2997D9 60.73%)",
            }}
          />
        </CardActions>
      </Card>
    </Box>
  );
};

export default SuccessView;
