import React from "react";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import AntSwitch from "../AntSwitch";
import { CircularProgress } from "@mui/material";

const EmailNotificationsList = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-12 d-flex my-2 notification-drawer-content-card">
        <div className="col-6 col-lg-6 px-0">
          <p className="mb-0">{t(props?.emailNotificationsInfo?.name)}</p>
        </div>

        <div className="col-6 col-lg-6 p-0 d-flex justify-content-start switchs_wrapper_style">
          <div className="col-6">
            {props?.emailNotificationsInfo?.type == "email" ||
            props?.emailNotificationsInfo?.type == "both" ? (
              <>
                {props?.emailSwitchLoader.includes(
                  props?.emailNotificationsInfo?.id
                ) ? (
                  <div className="spinner_wrapper_style">
                    <CircularProgress size={18} />
                  </div>
                ) : (
                  <AntSwitch
                    name="email"
                    value={props?.emailNotificationsInfo?.id}
                    onChange={props?.handleEmailSettingsSwitchChange}
                    checked={props?.emailNotificationsInfo?.email_status}
                  />
                )}
              </>
            ) : (
              <AntSwitch
                name="email"
                value={props?.emailNotificationsInfo?.id}
                disabled
              />
            )}
          </div>

          <div className=" col-6">
            {props?.emailNotificationsInfo?.type == "phone" ||
            props?.emailNotificationsInfo?.type == "both" ? (
              <>
                {props?.mobileSwitchLoader.includes(
                  props?.emailNotificationsInfo?.id
                ) ? (
                  <div className="spinner_wrapper_style">
                    <CircularProgress size={18} />
                  </div>
                ) : (
                  <AntSwitch
                    name="phone"
                    value={props?.emailNotificationsInfo?.id}
                    onChange={props?.handleEmailSettingsSwitchChange}
                    checked={props?.emailNotificationsInfo?.phone_status}
                  />
                )}
              </>
            ) : (
              <AntSwitch
                name="phone"
                value={props?.emailNotificationsInfo?.id}
                disabled
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emailSwitchLoader: state.super.emailSwitchLoader,
    mobileSwitchLoader: state.super.mobileSwitchLoader,
  };
};

export default connect(mapStateToProps)(EmailNotificationsList);
