import gql from "graphql-tag";

import {
  paginatorFragment,
  assignmentFragment,
  simplePaginatorFragment,
} from "../fragments";

/*
  Attendance History
*/

/*
  Penalties
*/

/*
  Assignments
*/

export const assignmentsListQuery = gql`
  query assignmentsList($input: AssignmentsInput) {
    Assignments(input: $input) {
      day
      date
      assignments {
        ...assignment
        locations: offices {
          id
          name
        }
      }
    }

    # Options
    users_by_role(first: 9999, input: { page_flag: "employees_list" }) {
      data {
        id
        name
        active
        department {
          id
        }
        office {
          id
        }
      }
    }
    work_timings_menu(new_versions_only: true) {
      id
      name
      work_timing_profile_type
    }
    locations: locations_menu(first: 999) {
      data {
        id
        name
      }
    }
  }
  ${assignmentFragment}
`;

export const assignmentFormQuery = gql`
  query assignmentForm(
    $includeInputs: Boolean! = false
    $includeAssignment: Boolean! = false
    $assignmentId: ID = ""
  ) {
    assignment(id: $assignmentId) @include(if: $includeAssignment) {
      ...assignment
      canEditRetain
      apply_on
      created_at
      allow_home
      allow_overtime
      allow_permission
      treat_as_normal
      normal_work_timing_id
      first_half_work_timing_id
      second_half_work_timing_id
      retain_default_work_timing
      firstHalfWorkTiming {
        id
        name
      }
      secondHalfWorkTiming {
        id
        name
      }
      assignmentApplicants {
        id
        applicable {
          __typename
          ... on Employee {
            user {
              id
              name
            }
          }
          ... on Department {
            id
            name
          }
          ... on Office {
            id
            name
          }
        }
      }
      employeeExcludes {
        user {
          id
          name
        }
      }
      assignmentCompensationConfigurations {
        type
        quantity
        payment_factor_id
        month_year_payroll
        request {
          time_from
          requestables {
            id
          }
        }
      }
      assignmentAbsenceAction {
        penalise
        compensate
      }
      ignoredEmployees {
        date
        name
      }
      weight
    }

    # Options
    users_by_role: assignmentUsersList @include(if: $includeInputs) {
      id
      name
      department {
        id
      }
      office {
        id
        name
      }
    }
    departments: assignmentDepartmentsList @include(if: $includeInputs) {
      id
      name
    }
    offices: assignmentOfficesList @include(if: $includeInputs) {
      id
      name
    }
    work_timings_menu(new_versions_only: true) @include(if: $includeInputs) {
      id
      name
      weight
      work_timing_profile_type
      work_hours
      sign_in_start_time
      sign_in_end_time
      sign_in_req
    }
  }
  ${assignmentFragment}
`;

export const changeAssignmentFormQuery = gql`
  query changeAssignmentForm(
    $assignmentId: ID
    $isIncludeAssignment: Boolean! = false
    $workTimingsMenuDate: Date
  ) {
    assignment(id: $assignmentId) @include(if: $isIncludeAssignment) {
      id
      from
      weight
      change_shift
      canEditRetain
      normalWorkTiming {
        id
        name
        weight
        work_timing_profile_type
      }
      firstHalfWorkTiming {
        id
        name
        weight
        work_timing_profile_type
      }
      secondHalfWorkTiming {
        id
        name
        weight
        work_timing_profile_type
      }
      normal_work_timing_id
      first_half_work_timing_id
      second_half_work_timing_id
      retain_default_work_timing
      workplace_setting
      workplace_setting_signout
      workplace_setting_identical
      additional_work_places: workPlacesIn
      additional_work_places_signout: workPlacesOut
      assignmentApplicants {
        applicable {
          ... on Employee {
            user {
              id
              name
            }
          }
        }
      }
    }

    # Options
    offices: assignmentOfficesList {
      id
      name
    }
    employees: assignmentUsersList {
      id
      name
      office {
        id
        name
      }
    }
    work_timings_menu(new_versions_only: true, archive:$workTimingsMenuDate) {
      id
      name
      weight
      work_timing_profile_type
    }
  }
`;

/*
  Monthly Report
*/

/*
  Yearly Report
*/

/*
  Facial Report
*/

export const facialReportListQuery = gql`
  query facialReportList(
    $page: Int
    $perPage: Int!
    $to: String
    $from: String
    $emp_id: [ID]
    $office_id: [ID]
    $department_id: [ID]
    $position_id: [ID]
    $isListView: Boolean = false
    $spoofed_img: Boolean = true
    $spoofed_loc: Boolean = true
    $isIncludeFilters: Boolean = true
  ) {
    gridView: users_sign_in_outs_by_role(
      first: $perPage
      page: $page
      input: {
        emp_id: $emp_id
        office_id: $office_id
        department_id: $department_id
        position_id: $position_id
        date_range: { to: $to, from: $from }
        has_face_recognation: true
        page: "history_logs"
        spoofed: $spoofed_img
        mocked_location: $spoofed_loc
      }
    ) @skip(if: $isListView) {
      data {
        id
        date
        sign_in_time
        sign_in_lng
        sign_in_lat
        sign_out_time
        sign_out_lng
        sign_out_lat
        checkIns {
          check_in_lng
          check_in_lat
          check_out_lng
          check_out_lat
          check_in_time
          check_out_time
          liveness_ratio_in
          liveness_ratio_out
          mocked_location_in
          mocked_location_out
          checkInLocation {
            name
          }
          checkOutLocation {
            name
          }
          checkInFace {
            path
          }
          checkOutFace {
            path
          }
        }
        sign_in_face {
          path
        }
        sign_out_face {
          path
        }
        sign_in_location {
          name
        }
        sign_out_location {
          name
        }
        liveness_ratio_in
        liveness_ratio_out
        mocked_location_in
        mocked_location_out
        employee {
          name
          id
          manager {
            id
          }
          copied_managers {
            id
          }
          face {
            path
          }
        }
      }
      paginatorInfo {
        ...simplePaginator
      }
    }
    listView: sign_ins_check_ins_list(
      page: $page
      first: $perPage
      input: {
        emp_id: $emp_id
        office_id: $office_id
        department_id: $department_id
        position_id: $position_id
        date_range: { to: $to, from: $from }
        has_face_recognation: true
        page: "history_logs"
        spoofed: $spoofed_img
        mocked_location: $spoofed_loc
      }
    ) @include(if: $isListView) {
      data {
        id
        type
        date
        in_lat
        in_lng
        out_lat
        out_lng
        in_time
        emp_name
        out_time
        in_face_path
        out_face_path
        in_location_name
        out_location_name
        liveness_ratio_in
        liveness_ratio_out
        mocked_location_in
        mocked_location_out
      }
      paginatorInfo {
        ...paginator
      }
    }

    # Options
    offices: company_offices(first: 9999) @include(if: $isIncludeFilters) {
      data {
        id
        name
      }
    }
    departments: company_departments(first: 9999)
      @include(if: $isIncludeFilters) {
      data {
        id
        name
      }
    }
    positions: company_positions(first: 9999) @include(if: $isIncludeFilters) {
      data {
        id
        name
      }
    }
    employees: users_by_role(
      first: 9999
      input: { page_flag: "attendance_history_list" }
    ) @include(if: $isIncludeFilters) {
      data {
        id
        name
      }
    }
  }

  ${paginatorFragment}
  ${simplePaginatorFragment}
`;
