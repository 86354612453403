import React, { useEffect } from "react";
import { InputForm, RadioboxForm } from "form-builder";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setDataTableSortingAction,
  fetchAllOfficesAction,
  deleteOfficeAction,
  onFormResetAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { Tooltip } from "@mui/material";
import HelperFns from "../../Helpers/HelperFns";
import {
  RemoveIconButton,
  EditIconButton,
} from "../../Components/IconButtonWithTooltip";
import Constants from "../../Constants";
import moment from "moment";
import ShowMoreText from "react-show-more-text";

const dataTableRef = "officesList";

const OfficesList = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const officesLimit =
    props.authPlanLimits?.find((feature) => feature?.id === "2")?.pivot
      ?.limits ?? Infinity;

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      width: "220px",
      cell: (row) => (
        <div dir={HelperFns.isStringRTL(row.name) ? "rtl" : "ltr"}>
          {row.name}
        </div>
      ),
    },
    {
      name: t("type"),
      cell: (row) => (row.is_workplace ? t("work place") : t("office")),
      width: "80px",
    },
    {
      name: t("language"),
      wrap: true,
      selector: "email_notification_locale",
      sortable: false,
      grow: 0.5,
      cell: (row) =>
        Constants.languageOptions.find(
          (lang) => lang.value == row?.email_notification_locale
        ).label,
    },
    {
      name: t("country"),
      wrap: true,
      selector: "country",
      sortable: false,
      width: "80px",

      cell: (row) => (
        <div dir={HelperFns.isStringRTL(row?.country?.name) ? "rtl" : "ltr"}>
          {row?.country?.name}
        </div>
      ),
    },
    {
      name: t("Currency"),
      wrap: true,
      sortable: false,
      width: "80px",
      cell: (row) => row?.currency?.name,
    },
    {
      name: t("regulation"),
      wrap: true,
      sortable: false,
      grow: 0.7,
      cell: (row) => row?.regulationType?.name,
    },
    {
      name: t("start of month"),
      wrap: true,
      selector: "getMonthStartDay",
      sortable: false,
      width: "70px",
    },
    {
      name: t("1st month"),
      wrap: true,
      sortable: false,
      grow: 0.7,
      cell: (row) => moment(row?.getYearStartMonth, "M").format("MMMM"),
    },
    {
      name: t("start week"),
      wrap: true,
      sortable: false,
      grow: 0.7,
      cell: (row) =>
        t(
          Constants.WeekDays?.find(
            (day) => day.value == row?.week_start_day
          )?.label?.toLowerCase()
        ),
    },

    {
      name: t("address"),
      wrap: true,
      selector: "address",
      sortable: false,
      grow: 1,
      width: "170px",
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={130}
        >
          <div
            className="py-1"
            dir={HelperFns.isStringRTL(row.address) ? "rtl" : "ltr"}
          >
            {row.address}
          </div>
        </ShowMoreText>
      ),
    },

    {
      name: t("time zone"),
      wrap: true,
      selector: "time_zone",
      sortable: false,
      grow: 1,
    },
    // {
    //   name: t("date"),
    //   wrap: true,
    //   selector: "created_at",
    //   sortable: false,
    //   grow: 1,
    // },
    {
      name: t("actions"),
      wrap: true,
      selector: "action",
      grow: 0.5,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_OFFICES]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <EditIconButton />
              <RemoveIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <EditIconButton href={`/offices/edit/${row.id}`} />
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </div>
        </HasPrivileges>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    props.fetchAllOfficesAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onFormResetAction(dataTableRef);
    };
  }, []);

  const handleFilter = () => {
    props.fetchAllOfficesAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].filter,
    props.data[dataTableRef].isWorkplace,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchAllOfficesAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, dir) => {
    props.fetchAllOfficesAction({
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };

  const handleDelete = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteOfficeAction(row.id);
      }
    });
  };

  return (
    <div className="mt-3">
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3 gap-20">
        <div className="col px-0">
          <InputForm
            name="filter"
            formName={dataTableRef}
            // label={<FontAwesomeIcon icon={faSearch} />}
            labelStyle="focus-label"
            placeholder={t("search")}
            containerStyle=" "
            inputStyle="search_input min-w-150"
            inputContainerStyle="search_container"
            icon="search"
          />
        </div>

        <RadioboxForm
          formName={dataTableRef}
          options={[
            { label: "All", value: null },
            { label: "offices", value: 0 },
            { label: "work places", value: 1 },
          ]}
          name="isWorkplace"
          optionInputStyle=" "
          containerStyle="mb-0"
          labelStyle="label-style"
          optionItemStyle="optionItemStyle"
          optionLabelStyle="optionLabelStyle"
          optionsContainerStyle="optionsContainerStyle"
        />

        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_OFFICES]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="pl-3 text-right">
              <button className="btn add_new_btn_style" type="button">
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                <span>{t("add new")}</span>
              </button>
            </div>
          }
        >
          <div className="pl-3 text-right">
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                props.data[dataTableRef]?.total >= officesLimit
                  ? t("you have reached the maximum number of offices")
                  : ""
              }
            >
              <button
                className="btn add_new_btn_style"
                type="button"
                onClick={() =>
                  props.data[dataTableRef]?.total >= officesLimit
                    ? null
                    : history.push(`/offices/new`)
                }
                disabled={props.data[dataTableRef]?.total >= officesLimit}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                <span>{t("add new")}</span>
              </button>
            </Tooltip>
          </div>
        </HasPrivileges>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        sortServer
        onSort={handleSorting}
        defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
        defaultSortField={props.data[dataTableRef].sorting.key}
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    authPlanLimits:
      state.auth?.userProfile?.company?.activeSubscription?.plan?.features,
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
  fetchAllOfficesAction,
  deleteOfficeAction,
  onFormResetAction,
})(OfficesList);
