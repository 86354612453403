import React from "react";
import Chart from "chart.js";
import chroma from "chroma-js";
import { Skeleton } from "@mui/material";

const CRMReportBarChart = ({ loading, chartData = [], ...props }) => {
  const id = `bar-chart-${new Date().getMilliseconds()}`;
  const colors = chroma
    .scale("Viridis")
    .mode("lch")
    .colors(chartData?.datasets.length);
  const data = {
    ...chartData,
    datasets: chartData?.datasets?.map((d, i) => ({
      ...d,
      backgroundColor: colors[i],
      borderColor: colors[i],
      borderWidth: 1,
    })),
  };

  React.useEffect(() => {
    if (!loading) {
      const ctx = document.getElementById(id).getContext("2d");

      new Chart(ctx, {
        type: "bar",
        data,
        options: {
          responsive: true,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          hover: {
            animationDuration: 0,
          },
        },
      });
    }
  }, [loading, JSON.stringify(data)]);

  return (
    <div className="d-flex justify-content-center mt-4">
      <div
        style={{
          height: 700,
          maxWidth: "100%",
          width: props?.child ? 900 : 1100,
        }}
      >
        {loading ? (
          <div className="h-100 mt-2">
            <div className="d-flex gap-10 mb-2 justify-content-center">
              {chartData?.datasets.map((d) => (
                <Skeleton
                  key={d?.label}
                  variant="rectangular"
                  width={50}
                  height={15}
                />
              ))}
            </div>
            <Skeleton
              height="100%"
              variant="rectangular"
              sx={{ borderRadius: 1 }}
            />
          </div>
        ) : (
          <canvas id={id} width="100%"></canvas>
        )}
      </div>
    </div>
  );
};

export default CRMReportBarChart;
