import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  styled,
  Tooltip,
} from "@mui/material";
import { DateTimePickerForm, BSelect, CheckboxBooleanForm } from "form-builder";
import "./FiltersDrawerStyle.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Const from "../../Constants";
import { pink } from "@mui/material/colors";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import NewMultiSelect from "./NewMultiSelect";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { updateValueAction } from "../../Store/Actions";
import { ToggleActive } from "../../Containers/AttendanceLogsList";

const UncheckedIcon = () => (
  <div
    style={{
      width: 16,
      height: 16,
      padding: 1.5,
    }}
  >
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#E9EFF7",
        borderRadius: 3,
        margin: 0,
        border: 0.5,
        borderColor: "#D7DEDD",
      }}
    />
  </div>
);

export const FilterCheckBox = ({ label, formName, name, value }) => {
  const dispatch = useDispatch();
  const handelChange = (e) => {
    dispatch(updateValueAction(formName, name, e?.target?.checked));
  };
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              margin: 0,
              padding: 0,
              marginRight: 1,
            }}
            icon={<UncheckedIcon />}
            checkedIcon={
              <CheckBoxIcon sx={{ color: "#009EFB", fontSize: 16 }} />
            }
            onChange={handelChange}
            name={name}
            checked={value}
          />
        }
        label={label}
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: 13,
            color: value ? "#313030" : "#57585A",
            fontWeight: "400",
          },
        }}
      />
    </>
  );
};

const FiltersDrawer = ({
  dataTableRef,
  isFilterDrawerOpen,
  toggleDrawer,
  onFilterReset,
  onFilterSubmit,
  employeesOptions,
  officesOptions,
  departmentsOptions,
  positionsOptions,
  formData,
  activeEmployeeToggler,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filteredEmployeesOptions = employeesOptions?.filter((emp) => {
    if (!props.isActive) return true; // return all the employees
    return props.isActive == Boolean(emp?.active);
  });

  return (
    <Drawer
      anchor={"right"}
      open={isFilterDrawerOpen}
      onClose={onFilterSubmit}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(87, 88, 90, 0.2)",
          },
        },
      }}
    >
      <div className="log-list-filters-drawer pb-5 d-flex flex-column gap-1 h-100 position-relative">
        <div className="d-flex justify-content-between align-items-center py-4 px-3">
          <label className="filters-header">{t("Filter Properties")}</label>
          <Button onClick={toggleDrawer(false)} className="px-0">
            <CloseIcon fontSize="small" sx={{ color: "#70bdea" }} />
          </Button>
        </div>
        <div className="px-4 d-flex flex-column gap-1" style={{paddingBottom:80}}>
          <div className="d-flex gap-1">
            <DateTimePickerForm
              name="from"
              formName={dataTableRef}
              label={t("from")}
              hasIcon
              reducer="super"
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              labelStyle="date_picker_label"
              placeholder={t("select date")}
              isLoading={formData?.isLoading}
            />

            <DateTimePickerForm
              name="to"
              formName={dataTableRef}
              label={t("to")}
              hasIcon
              reducer="super"
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              labelStyle="date_picker_label"
              placeholder={t("select date")}
              isLoading={formData?.isLoading}
            />
          </div>

          <div className="position-relative">
            {activeEmployeeToggler ? (
              <div className="position-absolute active-toogler-in-logs-filters">
                <ToggleActive
                  isActive={props.isActive}
                  isLoading={props.isLoading}
                  onEmpDataToggle={props.onEmpDataToggle}
                />
              </div>
            ) : null}
            <NewMultiSelect
              isLoading={formData?.isLoading}
              options={formData?.isEmpLoading ? [] : filteredEmployeesOptions}
              allOptions={employeesOptions}
              value={formData?.employees}
              dataTableRef={dataTableRef}
              name="employees"
              label={t("employees")}
              icon="people"
              placeHolder={t("employees")}
            />
          </div>

          <NewMultiSelect
            isLoading={formData?.isLoading}
            options={positionsOptions || []}
            value={formData?.positions}
            dataTableRef={dataTableRef}
            name="positions"
            label={t("positions")}
            icon="person"
            placeHolder={t("positions")}
          />

          <NewMultiSelect
            options={departmentsOptions}
            isLoading={formData?.isLoading}
            value={formData?.departments}
            dataTableRef={dataTableRef}
            name="departments"
            label={t("departments")}
            icon="building"
            placeHolder={t("departments")}
          />

          <NewMultiSelect
            options={officesOptions}
            isLoading={formData?.isLoading}
            value={formData?.offices}
            dataTableRef={dataTableRef}
            name="offices"
            label={t("offices")}
            icon="office"
            placeHolder={t("offices")}
          />
          {props?.children}
        </div>

        <div className="d-flex align-items-center position-fixed btns-container-logs-filter">
          <div className="apply-btn" onClick={onFilterSubmit}>
            {t("apply")}
          </div>
          <div className="reset-btn" onClick={onFilterReset}>
            {t("reset")}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FiltersDrawer;
