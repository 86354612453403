import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { dateTimeListFormat } from "../../Constants";

import MainModal from "../MainModal";
import DataTable from "react-data-table-component";

const ViewRelatedActivitiesModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  // Constants
  const columns = [
    {
      name: t("notes"),
      grow: 2,
      selector: "notes",
      wrap: true,
    },
    { name: t("created by"), selector: (row) => row?.user?.name },
    {
      name: t("date"),
      cell: ({ date }) => moment(date).format(dateTimeListFormat),
    },
    { name: t("type"), cell: ({ type }) => JSON.parse(type)?.name },
    {
      name: t("status"),
      cell: ({ status = [] }) => (
        <div>
          <strong className="d-block">{JSON.parse(status)?.[0]}</strong>
          {JSON.parse(status)?.[1]}
        </div>
      ),
    },
  ];

  return (
    <MainModal
      isOpen
      size="lg"
      toggle={onClose}
      hasModalFooter={false}
      modalTitle="View Related Activities"
    >
      <DataTable noHeader data={data?.activities} columns={columns} />
    </MainModal>
  );
};

export default ViewRelatedActivitiesModal;
